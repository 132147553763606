
import Vue, { PropType } from "vue";
import Composition from "@/components/v2/FeedingPlan/FeedingPlanDetails/Composition.vue";
import NutritionalInfo, {
  NutritionalInfoProps
} from "@/components/v2/FeedingPlan/FeedingPlanDetails/NutritionalInfo.vue";
import FeedingPlan from "@/components/v2/FeedingPlan/FeedingPlanDetails/FeedingPlan.vue";
import {  CompositionDTO } from "@/api/v2/feeding-plan";
import { DailyCaloriesProps } from "@/components/v2/FeedingPlan/FeedingPlanDetails/DailyCalories.vue";
import type { FeedingGuideDTO, BlendProductDTO } from "@/api/v2/feeding-plan";

export default Vue.extend({
  name: "FeedingPlanDetails",
  components: { Composition, NutritionalInfo, FeedingPlan },
  props: {
    blendProduct: { type: Object as PropType<BlendProductDTO> },
    composition: { type: Object as PropType<CompositionDTO> },
    dailyCalories: { type: Object as PropType<DailyCaloriesProps> },
    feedingGuide: { type: Object as PropType<FeedingGuideDTO> },
    nutritionalInfo: { type: Object as PropType<NutritionalInfoProps> },
    petName: String,
    editable: {type: Boolean, default: false}
  },
  data() {
    return {
      tab: null,
      items: ["FEEDING PLAN", "COMPOSITION", "NUTRITIONAL INFO"]
    };
  }
});
