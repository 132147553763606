
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";

import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import { AuthActionsNames } from "@/store/auth/auth.actions";
import ZendeskService from "@/services/zendesk.service";
import { mapGetters } from "vuex";

type Data = {
  username: string;
  password: string;
  vetLoginErrorMessage?: string;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  login(event: Event): void;
  dispatchGTMLoginEvent(): void;
  updateZendeskToken(): void;
};

export default Vue.extend<Data, Methods, any, {} & Inject>({
  name: "Login",
  components: { WithOldVetBar },
  inject: ["Api"],
  data() {
    return {
      username: "",
      password: "",
      vetLoginErrorMessage: ""
    };
  },
  computed: {
    ...mapGetters(["zendeskSSOFlow"])
  },
  methods: {
    updateZendeskToken(): void {
      setInterval(() => {
        ZendeskService.setup();
      }, 3 * 60 * 1000);
    },
    async login() {
      try {
        if (this.zendeskSSOFlow) {
          await ZendeskService.setup();
          this.updateZendeskToken();
        } else {
          await ZendeskService.getZendeskJwtToken(); // pull token to create feature flag by backend
        }
      } catch {
        // do nothing, don't block second request
      }

      try {
        await this.$store.dispatch(AuthActionsNames.VET_LOGIN, {
          username: this.username,
          password: this.password
        });

        await this.dispatchGTMLoginEvent();
        await this.$router.push("/");
      } catch {
        this.vetLoginErrorMessage = this.$store.getters.errorMessage;
      }
    },
    async dispatchGTMLoginEvent() {
      this.$gtm.trackEvent({
        event: "vet_login",
        vet_name: this.$store.getters.vetName,
        vet_practice_name: this.$store.getters.vetPracticeGroupName,
        username: this.username
      });
    }
  }
});
