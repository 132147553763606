
import Vue from "vue";
import CheckoutBag from "@/components/v2/Checkout/CheckoutBag.vue";
import CheckoutFeedingPlan from "@/components/v2/Checkout/CheckoutFeedingPlan.vue";
import CheckoutHelp from "@/components/v2/Checkout/CheckoutHelp.vue";
import CheckoutPrice from "@/components/v2/Checkout/CheckoutPrice.vue";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import DeliveryDates from "@/components/v2/DeliveryDates.vue";
import courierService, { DeliveryWindow } from "@/services/courier.service";
import priceBreakdownService, { OrderInfoDto } from "@/services/priceBreakdown.service";

export default Vue.extend({
  name: "CheckoutInformation",
  components: {
    LoadingCircle,
    CheckoutBag,
    CheckoutFeedingPlan,
    CheckoutHelp,
    CheckoutPrice,
    DeliveryDates
  },
  props: {
    postcode: { type: String, default: null }
  },
  data() {
    return {
      customerId: +this.$route.params.customer_id,
      deliveryWindow: null as DeliveryWindow | null,
      deliveryWindowWithoutPostcode: null as DeliveryWindow | null,
      deliveryWindowMap: new Map(),
      orderInfo: null as OrderInfoDto | null
    };
  },
  async mounted() {
    await Promise.all([this.loadOrderInfo(), this.loadDeliveryWindowWithNoPostcode()]);
  },
  methods: {
    async loadDeliveryWindow() {
      const postcode = this.postcode.toLowerCase();
      const cachedWindow = this.deliveryWindowMap.get(postcode);

      if (cachedWindow) {
        this.deliveryWindow = cachedWindow;
        return;
      }

      const newWindow = await courierService.getFirstAvailableWindow(this.postcode);
      this.deliveryWindow = newWindow;
      this.deliveryWindowMap.set(postcode, newWindow);
    },
    async loadDeliveryWindowWithNoPostcode() {
      this.deliveryWindow = await courierService.getFirstAvailableWindow();
    },
    async loadOrderInfo() {
      this.orderInfo = await priceBreakdownService.getOrderInfoForCustomer(
        this.customerId as number
      );
    }
  },
  watch: {
    postcode() {
      if (this.postcode) {
        this.loadDeliveryWindow();
      } else {
        this.deliveryWindow = null;
      }
    }
  }
});
