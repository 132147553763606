
import Vue from "vue";
import round from "@/utils/round.function";

enum presentationEnum {
  PERCENTAGE,
  KCAL
}
export default Vue.extend<any, any, any, any>({
  name: "PortionSliderVisualization",
  props: {
    targetKcalPercentage: {
      type: Number,
      required: true
    },
    dailyTargetKcal: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      presentationEnum: presentationEnum,
      showIn: presentationEnum.KCAL
    };
  },
  computed: {
    targetKcal() {
      return (this.dailyTargetKcal * this.targetKcalPercentage) / 100;
    },
    otherKcal() {
      return this.dailyTargetKcal - this.targetKcal;
    }
  },
  filters: {
    round: round
  }
});
