
import PortionSliderVisualization from "@/components/PortionAllocation/PortionSliderVisualization.vue";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PortionAllocationComponent",
  props: ["petDetails", "feedingPlan", "customerId"],
  components: { PortionSliderVisualization },
  data() {
    return {
      kcalChange: 100
    };
  },
  computed: {
    ...mapGetters(["originalDailyKcalRequirement", "totalDailyKcalRequirement"])
  },
  methods: {
    mounted() {
      this.kcalChange = this.feedingPlan.target_kcal_percent;
    },
    pluralize(count: number, noun: string): string {
      return `${count} ${noun}${count !== 1 ? "s" : ""}`;
    }
  },
  filters: {
    mgToKg(mg: number): number {
      return mg / 1000;
    },
    round(value: number, precision = 2): number {
      return Number.parseFloat(value.toFixed(precision));
    }
  }
});
