import axios from "axios";
import { ConditionRecordsHistoryResponse } from "@/api/api.dto";

export class ConditionRecordService {
  private readonly baseUrl = "/api/vets/v1/pet";

  public getPetConditionRecords(
    petId: number,
    page = 1,
    size = 10
  ): Promise<ConditionRecordsHistoryResponse> {
    return axios
      .get(`${this.baseUrl}/${petId}/conditions-history`, { params: { page: page, size: size } })
      .then(response => {
        return {
          condition_records: response.data.resp.condition_records,
          pagination: response.data.pagination,
          success: true
        };
      })
      .catch(() => {
        return { success: false };
      });
  }
}

const conditionRecordService = new ConditionRecordService();
export default conditionRecordService;
