
import { DietType } from "@/api/api.dto";
import { ApiInterface } from "@/api/api.interface";
import Vue from "vue";

type HealthIssueOption = {
  display_name_default: string;
  health_issue_id: number;
  name: string;
};

type Data = {
  dietType: DietType;
  options: {
    firstLevel?: HealthIssueOption[];
    secondLevel?: HealthIssueOption[];
    thirdLevel?: HealthIssueOption[];
  };
  selected: {
    primary?: number;
    secondary?: number;
    tertiary?: number;
  };
};

type Inject = {
  Api: ApiInterface;
};

export default Vue.extend<any, any, any, any & Inject>({
  name: "MaintenanceDiet",
  inject: ["Api"],
  props: {
    petId: Number,
    isPuppy: Boolean,
    petName: String,
    savedSelected: Object
  },
  data() {
    return {
      dietType: DietType.Maintenance,
      options: {
        firstLevel: [],
        secondLevel: [],
        thirdLevel: []
      },
      selected: {
        primary: this.savedSelected.primary,
        secondary: this.savedSelected.secondary,
        tertiary: this.savedSelected.tertiary
      },
      loading: true
    };
  },
  async created() {
    const result = await this.Api.getSelectableHealthIssues({
      pet_id: this.petId,
      diet_type: this.dietType
    });
    this.options.firstLevel = result.resp.health_issues;
    this.loading = false;
    this.$emit("loaded");
  },
  async mounted() {
    if (this.selected.primary) {
      const secondaryIssues = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: this.dietType,
        primary_health_issue_id: this.selected.primary
      });
      this.options.secondLevel = secondaryIssues.resp.health_issues;
      this.$emit("validateSelection", true);
    }

    if (this.selected.primary && this.selected.secondary) {
      const tertiaryIssues = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: this.dietType,
        primary_health_issue_id: this.selected.primary,
        secondary_health_issue_id: this.selected.secondary
      });
      this.options.thirdLevel = tertiaryIssues.resp.health_issues;
      this.$emit("validateSelection", true);
    }
  },
  methods: {
    async onFirstOptionSelected(event: any): Promise<void> {
      this.$emit("clearInvalidSelectionErrorMessage");
      // Reset second options and selection
      this.options.secondLevel = [];
      this.options.thirdLevel = [];
      this.selected = { primary: parseInt(event.target.value, 10) };

      this.$emit("onHealthIssuesSelected", this.selected);

      // Set second option
      const result = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: this.dietType,
        primary_health_issue_id: this.selected.primary
      });
      this.options.secondLevel = result.resp.health_issues;
      if (this.options.secondLevel.length === 0) {
        this.$emit("validateSelection", true);
      }
    },
    async onSecondOptionSelected(event: any): Promise<void> {
      this.$emit("clearInvalidSelectionErrorMessage");
      // Reset second options and selection
      this.options.thirdLevel = [];
      this.selected = {
        primary: this.selected.primary,
        secondary: parseInt(event.target.value, 10)
      };

      this.$emit("onHealthIssuesSelected", this.selected);

      // Set third option
      const result = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: this.dietType,
        primary_health_issue_id: this.selected.primary,
        secondary_health_issue_id: this.selected.secondary
      });
      this.options.thirdLevel = result.resp.health_issues;
      if (this.options.thirdLevel.length === 0) {
        this.$emit("validateSelection", true);
      }
    },

    onTertiaryOptionSelected(event: any) {
      this.$emit("clearInvalidSelectionErrorMessage");
      this.selected.tertiary = event.target.value;
      this.selected = {
        primary: this.selected.primary,
        secondary: this.selected.secondary,
        tertiary: parseInt(event.target.value, 10)
      };
      this.$emit("onHealthIssuesSelected", this.selected);
      this.$emit("validateSelection", true);
    }
  },
  computed: {
    showPrimary() {
      return !(this.isPuppy && this.options.firstLevel.length === 0);
    }
  }
});
