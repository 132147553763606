
import Vue from "vue";
import BenefitRecord from "@/components/v2/FeedingPlan/BenefitRecord.vue";
import { BenefitsDTO } from "@/api/v2/feeding-plan";

export default Vue.extend<any, any, any, any>({
  name: "KeyBenefits",
  components: { BenefitRecord },
  props: {
    keyBenefits: Array as () => Array<BenefitsDTO>
  }
});
