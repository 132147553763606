
import Vue, { PropType } from "vue";

export interface DailyCaloriesProps {
  ageDisplay: string;
  breed: string;
  originalTargetKcal: number;
  petId: number;
  petName: string;
  petWeight: number;
  targetDailyDryKcal: number;
  targetKcalPercent: number;
}

export default Vue.extend({
  name: "DailyCalories",
  props: {
    dailyCalories: { type: Object as PropType<DailyCaloriesProps> },
    editable: { type: Boolean, default: false },
    customerId: { type: Number }
  }
});
