
import Vue from "vue";
import KeyBenefits from "@/components/v2/FeedingPlan/KeyBenefits.vue";
import FeedingPlanImage from "@/components/v2/FeedingPlan/FeedingPlanImage.vue";
import FeedingPlanDetails from "@/components/v2/FeedingPlan/FeedingPlanDetails/FeedingPlanDetails.vue";
import FeedingPlanDetailsModal from "@/components/v2/FeedingPlan/FeedingPlanDetailsModal.vue";
import DeliveryDates from "@/components/v2/DeliveryDates.vue";
import WithAppBar from "@/layouts/WithAppBar.vue";
import { FeedingPlanAPI, NutritionalPlanDTO } from "@/api/v2/feeding-plan";
import { PetAPI, PetDataDto, PetState, PetStatusData } from "@/api/v2/pet";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import productService, { ProductDTO, SubscriptionProductDTO } from "@/services/product.service";
import { formatPrice } from "@/utils/currency";
import courierService, { DeliveryWindow } from "@/services/courier.service";
import FeedingPlanPrice, {
  FeedingPlanPriceBreakdown
} from "@/components/v2/FeedingPlan/FeedingPlanPrice.vue";
import OOCCModal from "@/components/v2/FeedingPlan/OOCCModal.vue";
import { CustomerAPI, CustomerDetailsDTO } from "@/api/v2/customer";
import Alert, { AlertData } from "@/components/v2/Alert.vue";
import subscriptionService from "@/services/subscription.service";
import { SubscriptionStatus } from "@/models/subscription.model";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import moment from "moment";
import feedingPlanService, { FeedingPlanHistoryDate } from "@/services/feedingPlan.service";
import { ACTIONS } from "@/store";
import { RouteNames } from "@/router/routeNames";

interface FeedingPlanActionData {
  text: string;
  action: () => void;
  actionDisabled?: boolean;
  ooccDisabled?: boolean;
}

export default Vue.extend<any, any, any, any>({
  name: "FeedingPlan",
  components: {
    BreadCrumbs,
    Alert,
    OOCCModal,
    FeedingPlanPrice,
    LoadingCircle,
    WithAppBar,
    FeedingPlanImage,
    FeedingPlanDetailsModal,
    KeyBenefits,
    FeedingPlanDetails,
    DeliveryDates
  },
  data() {
    return {
      loadings: {
        petData: true,
        nutritionalPlan: true,
        fortiflora: true,
        oocc: false,
        priceBreakdown: true
      },
      petId: +this.$route.params.pet_id,
      customerId: +this.$route.params.customer_id,
      petData: null as PetDataDto | null,
      contactDetails: null as CustomerDetailsDTO | null,
      nutritionalPlan: null as NutritionalPlanDTO | null,
      fortiflora: null as ProductDTO | null,
      fortifloraAdded: false,
      subscriptionProducts: [] as Array<SubscriptionProductDTO>,
      deliveryWindow: null as DeliveryWindow | null,
      showOOCCModal: false,
      ooccSuccess: false,
      ooccError: "",
      alertData: [] as Array<AlertData>,
      petsStatusesData: [] as Array<PetStatusData>,
      feedingPlanHistory: [] as Array<FeedingPlanHistoryDate>,
      disableActionButton: false
    };
  },
  methods: {
    async loadCustomersPetData() {
      this.petData = await PetAPI.getPetData(this.petId);
      this.contactDetails = await CustomerAPI.getContactDetails(this.petData.customerId);

      if (this.petData.deliveryWindow) {
        if (this.petData.deliveryWindow.fulfilment.isSameOrAfter(moment(), "days")) {
          /* If fulfilment is in future it's a correct window. Old dates */
          /* can still be there for example subscription cancelled year ago */
          this.deliveryWindow = this.petData.deliveryWindow;
        }
      }

      this.loadings.petData = false;
    },
    async loadCustomerPetsStatuses() {
      this.petsStatusesData = await PetAPI.getCustomerPetsStatusesByAnyPetId(this.petId);
    },
    async loadNutritionalPlan() {
      this.nutritionalPlan = await FeedingPlanAPI.getNutritionalPlan(this.petId);
      this.loadings.nutritionalPlan = false;
      this.loadings.priceBreakdown = false;
    },
    async loadHistoricalFeedingPlanIds() {
      const response = await feedingPlanService.getPetFeedingPlanDates(this.petId);
      if (response.success) {
        this.feedingPlanHistory = response.feedingPlanDates;
      }
    },
    async loadPriceBreakdown() {
      this.nutritionalPlan.priceBreakdown = await FeedingPlanAPI.getPriceBreakdownForPet(
        this.petId
      );
      this.loadings.priceBreakdown = false;
    },
    async loadAllProductsData() {
      await Promise.all([this.loadFortifloraProduct(), this.loadSubscriptionProducts()]);
      this.loadings.fortiflora = false;
    },
    async loadFortifloraProduct() {
      const products = await productService.getProducts();
      this.fortiflora = products[0];
    },
    async loadSubscriptionProducts() {
      this.subscriptionProducts = await productService.getSubscriptionProducts(
        this.petData?.subscription.subscriptionId
      );
      this.fortifloraAdded = this.isFortifloraAdded();
    },
    async loadDeliveryWindow() {
      this.deliveryWindow = await courierService.getFirstAvailableWindow();
    },
    goToFortifloraPage() {
      this.$router.push({
        name: this.routeNames.FORTIFLORA,
        params: {
          pet_id: this.petId,
          customer_id: this.customerId
        }
      });
    },
    async changeFortifloraSubscription(value: boolean) {
      this.loadings.priceBreakdown = true;
      if (value) {
        await this.addFortiflora();
      } else {
        await this.removeFortiflora();
      }
      await this.loadPriceBreakdown();
      this.loadings.fortiflora = false;
    },
    async addFortiflora() {
      this.loadings.fortiflora = true;
      await productService.addToSubscription(
        this.petData?.subscription.subscriptionId,
        this.fortiflora?.productIdentifier
      );
      await this.loadSubscriptionProducts();
    },
    async removeFortiflora() {
      this.loadings.fortiflora = true;
      await productService.removeFromSubscription(
        this.petData?.subscription.subscriptionId,
        this.fortiflora?.productIdentifier
      );
      await this.loadSubscriptionProducts();
    },
    isFortifloraAdded(): boolean {
      if (this.fortiflora && this.subscriptionProducts) {
        return this.subscriptionProducts.some(
          (product: SubscriptionProductDTO) =>
            product.productIdentifier === this.fortiflora.productIdentifier
        );
      }
      return false;
    },
    async toOutOfClinicCheckout() {
      this.showOOCCModal = false;
      this.loadings.oocc = true;

      const response = await CustomerAPI.initOutOfClinicCheckout(this.petId);
      this.ooccSuccess = response.success;

      // this api can return error with status 200
      if (response.errorMessage) {
        this.ooccError = response.errorMessage;
      }

      this.loadings.oocc = false;
      this.showOOCCModal = true;
    },
    ooccRetry() {
      this.toOutOfClinicCheckout();
    },
    goToDashboard() {
      this.$router.push({ name: this.routeNames.DASHBOARD });
    },
    async goToCustomerHub(alert?: AlertData) {
      await this.$router.push({
        name: this.routeNames.CUSTOMER_HUB,
        params: { customer_id: this.petData?.customerId, alert: alert }
      });
    },
    async goToPetDetails() {
      await this.$router.push({
        name: this.prototypeRouteNames.BLEND_CONFIGURATION,
        params: { customer_id: this.petData?.customerId }
      });
    },
    async reactivateSubscription() {
      this.disableActionButton = true;
      const response = await subscriptionService.reactivateSubscriptionForPet(this.petId);
      if (response.errorMessage) {
        this.disableActionButton = false;
        return;
      }
      const message = "Subscription has been reactivated.";
      await this.goToCustomerHub({ message: message, type: "success" });
      this.disableActionButton = false;
    },
    async goToPricingPage() {
      this.disableActionButton = true;
      await this.$router.push({
        name: RouteNames.PRICE,
        params: { customer_id: this.petData?.customerId }
      });
      this.disableActionButton = false;
    },
    setupSubscriptionAlert() {
      let alertMessage = null;
      const petState = this.petData?.getPetState();
      if (petState === PetState.CANCELLED) {
        alertMessage = `We now have all the information needed to reactivate \
          ${this.petData?.name}'s subscription. \
          Please click reactivate button for ${this.petData?.name} \
          to start receiving deliveries.`;
      } else if (petState === PetState.COMPLETE_OR_PAUSED) {
        alertMessage = `We now have all the information needed to activate \
          ${this.petData?.name}'s subscription. \
          Please click activate button for ${this.petData?.name} \
          to start receiving deliveries.`;
      }
      if (alertMessage) {
        this.alertData.push({
          message: alertMessage,
          type: "warning"
        });
      }
    },
    setupWeightUpdateAlert() {
      const alert = this.$route.params.alert;
      if (alert) {
        this.alertData.push({
          message: alert.message,
          type: alert.type
        });
      }
    },
    setupAlerts() {
      this.setupSubscriptionAlert();
      this.setupWeightUpdateAlert();
    },
    isAnyOtherPetActive(): boolean {
      const otherActive = this.petsStatusesData.filter(
        (value: PetStatusData) =>
          value.subscriptionStatus === SubscriptionStatus.ACTIVE && value.petId !== this.petId
      );
      return otherActive.length > 0;
    }
  },
  computed: {
    titleDisplay() {
      return this.petData ? `${this.petData?.name}'s nutritional plan` : "";
    },
    keyBenefits() {
      if (this.nutritionalPlan) {
        return this.nutritionalPlan.blendData.claims;
      }
      return [];
    },
    fortifloraPriceDisplay() {
      if (this.fortiflora) {
        return formatPrice(this.fortiflora.price);
      }
      return "";
    },
    priceBreakdownData(): FeedingPlanPriceBreakdown | null {
      if (this.nutritionalPlan) {
        return {
          days: this.nutritionalPlan.orderDays,
          total: this.nutritionalPlan.priceBreakdown.totalPriceVatIncluded,
          totalBeforeDiscount: this.nutritionalPlan.priceBreakdown.totalPriceBeforeDiscount,
          totalPricePerDay: this.nutritionalPlan.priceBreakdown.pricePerDay
        };
      }
      return null;
    },
    feedingPlanActionData(): FeedingPlanActionData {
      const petState = this.petData?.getPetState();

      if (petState === PetState.NEEDS_CHECKOUT) {
        if (this.isAnyOtherPetActive()) {
          return {
            text: "ACTIVATE",
            action: this.goToPricingPage,
            ooccDisabled: true
          };
        }
        return { text: "CHECKOUT", action: this.goToPricingPage };
      }
      if (petState === PetState.COMPLETE_OR_PAUSED) {
        return {
          text: "ACTIVATE",
          action: this.goToPricingPage,
          ooccDisabled: true
        };
      }
      if (petState === PetState.CANCELLED) {
        return {
          text: "REACTIVATE",
          action: this.reactivateSubscription,
          ooccDisabled: true
        };
      }
      // subscription is active
      return {
        text: "CHECKOUT",
        action: this.goToPricingPage,
        actionDisabled: true,
        ooccDisabled: true
      };
    },
    dailyCalories() {
      return {
        ageDisplay: this.petData.age.ageDisplay(),
        breed: this.petData.breed.name,
        originalTargetKcal: this.nutritionalPlan.originalTargetKcal,
        petId: this.petData.petId,
        petName: this.petData.name,
        petWeight: this.petData.weight,
        targetDailyDryKcal: this.nutritionalPlan.targetDailyDryKcal,
        targetKcalPercent: this.nutritionalPlan.targetKcalPercent
      };
    }
  },
  async mounted() {
    await Promise.all([
      this.loadCustomersPetData(),
      this.loadCustomerPetsStatuses(),
      this.loadNutritionalPlan(),
      this.loadHistoricalFeedingPlanIds()
    ]);
    if (!this.deliveryWindow) {
      await this.loadDeliveryWindow();
    }

    await this.loadAllProductsData();
    this.setupAlerts();

    this.$store.dispatch(ACTIONS.SET_PET_DETAILS, this.petData);
    this.$store.dispatch(ACTIONS.SET_FEEDING_PLAN, this.nutritionalPlan);
  }
});
