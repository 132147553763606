import { PaymentClientSecretResponse } from "@/api/api.dto";
import axios from "axios";
import { ApiException } from "@/api/v2/api-exception";

export interface PaymentClientSecretDTO {
  clientSecret: string;
  errorMessage?: string;
}

export interface CurrentPaymentMethodDTO {
  paymentMethodExists: boolean;
  lastFourDigits?: string;
  errors?: any;
}

export class PaymentAPI {
  static CLIENT_SECRET_URL = "/api/vets/v1/customer/payment-method";
  static CUSTOMER_URL = "/api/vets/v1/customer";

  static async getSecretKey(): Promise<PaymentClientSecretDTO> {
    const url = this.CLIENT_SECRET_URL;
    const response = {} as PaymentClientSecretResponse;
    try {
      const axiosResponse = await axios.get(url);
      response.clientSecret = axiosResponse.data.resp.client_key;
    } catch (error) {
      throw new ApiException(error.message);
    }
    return response;
  }

  static async getCurrentPaymentMethod(customerId: number): Promise<CurrentPaymentMethodDTO> {
    try {
      const axiosResponse = await axios.get(
        `${this.CUSTOMER_URL}/${customerId}/current-payment-method`
      );
      return {
        paymentMethodExists: axiosResponse.data.resp.payment_method_exists,
        lastFourDigits: axiosResponse.data.resp.last_four_digits
      };
    } catch (error) {
      throw new ApiException("Could not get payment method", error.response.data.resp.errors);
    }
  }

  static async postPaymentToken(token: string, customerId: number, petId: number) {
    try {
      await axios.post(`${this.CUSTOMER_URL}/${customerId}/payment-method`, {
        stripe_token: token,
        pet_id: petId
      });
    } catch (errors) {
      throw new ApiException(errors.message, errors.response.data.resp.errors);
    }
  }
}
