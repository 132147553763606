
import Vue from "vue";
import CheckoutInformation from "@/components/v2/Checkout/CheckoutInformation.vue";
import {
  AddressDTO,
  CustomerAPI,
  CustomerDetailsDTO,
  CustomerDetailsUpdateDTO
} from "@/api/v2/customer";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import { RouteNames } from "@/router/routeNames";
import { PaymentAPI } from "@/api/v2/payment";
import { validators } from "@/validators";
import { Patterns } from "@/validators/regex";

export default Vue.extend({
  name: "CheckoutForm",
  components: { LoadingCircle, CheckoutInformation },
  data() {
    return {
      customerId: +this.$route.params.customer_id,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        contactConsent: false,
        deliveryAddress: {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        },
        billingSameAsShipping: false,
        billingAddress: {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        }
      },
      mainErrors: {},
      deliveryAddressErrors: {},
      billingAddressErrors: {},

      loading: true,
      formSubmitted: false,
      formValid: false,
      rules: {
        required: [validators.required()],
        email: [validators.required("E-mail"), validators.email()],
        postalcode: [validators.required("Postcode"), validators.postalCode()]
      },
      validDeliveryPostcode: null as string | null
    };
  },
  async mounted() {
    this.loading = true;
    await this.getContactDetails();
    this.loading = false;
  },
  methods: {
    async getContactDetails() {
      const contactDetails = await CustomerAPI.getContactDetails(this.customerId);
      this.fillFormData(contactDetails);
    },
    fillFormData(data: CustomerDetailsDTO) {
      this.formData.firstName = data.first;
      this.formData.lastName = data.last;
      this.formData.email = data.email;
      this.formData.phoneNumber = data.mobilePhone;
      this.formData.contactConsent = data.contactConsent;
      this.formData.billingSameAsShipping = data.billingSameAsShipping;
      this.formData.deliveryAddress = this.getAddressDisplay(data.deliveryAddress);
      this.formData.billingAddress = this.getAddressDisplay(data.billingAddress);
    },
    getAddressDisplay(address?: AddressDTO) {
      if (!address) {
        return {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        };
      }
      return {
        firstLine: address.firstLine || "",
        secondLine: address.secondLine || "",
        city: address.city || "",
        postalcode: address.postalCode || ""
      };
    },
    async submitFrom() {
      const response = await CustomerAPI.putCheckoutData(this.customerId, this.parseFormData());
      if (response.data.resp.errors) {
        this.deliveryAddressErrors = response.data.resp.errors.deliveryAddress;
        delete response.data.resp.errors.deliveryAddress;

        this.billingAddressErrors = response.data.resp.errors.billingAddress;
        delete response.data.resp.errors.billingAddress;

        this.mainErrors = response.data.resp.errors;
      } else {
        this.formSubmitted = true;

        // redirect to the old card update view if payment method exists
        // todo: remove it with the new card update page
        const currentPaymentMethod = await PaymentAPI.getCurrentPaymentMethod(this.customerId);
        if (currentPaymentMethod.paymentMethodExists) {
          await this.$router.push({ name: RouteNames.PAYMENT_DETAILS });
        } else {
          await this.$router.push({ name: RouteNames.PAYMENT });
        }
      }
    },
    updatePostcodeForWindow(postcode: string) {
      if (Patterns.postcode.test(postcode)) {
        this.validDeliveryPostcode = postcode;
      } else {
        this.validDeliveryPostcode = null;
      }
    },
    parseFormData(): CustomerDetailsUpdateDTO {
      const deliveryAddress = this.formData.deliveryAddress;
      const billingAddress = this.formData.billingAddress;
      const billingSameAsShipping = this.formData.billingSameAsShipping;

      return new CustomerDetailsUpdateDTO(
        this.formData.email,
        this.formData.firstName,
        this.formData.lastName,
        this.formData.phoneNumber,
        this.formData.contactConsent,
        billingSameAsShipping,
        new AddressDTO(
          deliveryAddress.firstLine,
          deliveryAddress.city,
          deliveryAddress.postalcode,
          deliveryAddress.secondLine
        ),
        billingSameAsShipping
          ? undefined
          : new AddressDTO(
              billingAddress.firstLine,
              billingAddress.city,
              billingAddress.postalcode,
              billingAddress.secondLine
            )
      );
    }
  }
});
