import axios from "axios";
import { ActivateSubscriptionResponse } from "@/api/api.dto";

export interface KcalTargetData {
  pet_id: number;
  target_daily_kcal: number;
  target_kcal_multiplier: number;
  other_kcal_percent: number;
}

class SubscriptionService {
  private baseUrl = "/api/vets/v1/subscription/";
  private petSubscriptionUrl = "/api/vets/v1/pet/";

  changeKcalTarget(data: KcalTargetData): Promise<any> {
    return axios.put(`${this.baseUrl}kcal-target`, data);
  }

  async activateSubscriptionForPet(petId: number): Promise<ActivateSubscriptionResponse> {
    const response = { success: false } as ActivateSubscriptionResponse;
    try {
      await axios.put(`${this.petSubscriptionUrl}${petId}/activate-subscription`);
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not activate subscription";
    }
    return response;
  }
  async reactivateSubscriptionForPet(petId: number): Promise<ActivateSubscriptionResponse> {
    const response = { success: false } as ActivateSubscriptionResponse;
    try {
      await axios.put(`${this.petSubscriptionUrl}${petId}/reactivate-subscription`);
      response.success = true;
    } catch (error) {
      response.errorMessage = "Could not reactivate subscription";
    }
    return response;
  }
}
const subscriptionService = new SubscriptionService();
export default subscriptionService;
