
import Vue from "vue";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import { AddressDTO, CustomerAPI, CustomerDetailsDTO } from "@/api/v2/customer";
import StripePayment from "@/components/v2/Payment/StripePayment.vue";
import { PaymentAPI } from "@/api/v2/payment";

class CheckoutDataError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CheckoutDataError";
  }
}
export default Vue.extend<any, any, any, any>({
  name: "PaymentForm",
  components: { StripePayment, LoadingCircle },
  data() {
    return {
      customerId: +this.$route.params.customer_id,
      petId: +this.$route.params.pet_id,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        contactConsent: false,
        deliveryAddress: {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        },
        billingSameAsShipping: false,
        billingAddress: {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        }
      },
      loading: true
    };
  },
  methods: {
    async getContactDetails() {
      const contactDetails = await CustomerAPI.getContactDetails(this.customerId);
      this.fillFormData(contactDetails);
    },
    fillFormData(data: CustomerDetailsDTO) {
      this.formData.firstName = data.first;
      this.formData.lastName = data.last;
      this.formData.email = data.email;
      this.formData.phoneNumber = data.mobilePhone;
      this.formData.contactConsent = data.contactConsent;
      this.formData.billingSameAsShipping = data.billingSameAsShipping;
      this.formData.deliveryAddress = this.getAddressDisplay(data.deliveryAddress);
      this.formData.billingAddress = this.getAddressDisplay(data.billingAddress);
    },
    getAddressDisplay(address?: AddressDTO) {
      if (!address) {
        return {
          firstLine: "",
          secondLine: "",
          city: "",
          postalcode: ""
        };
      }
      return {
        firstLine: address.firstLine || "",
        secondLine: address.secondLine || "",
        city: address.city || "",
        postalcode: address.postalCode || ""
      };
    },
    validateContactInformation(): boolean {
      return !!(
        this.formData.email &&
        this.formData.firstName &&
        this.formData.lastName &&
        this.formData.phoneNumber
      );
    },
    validateAddress(): boolean {
      const { firstLine, city, postalcode } = this.formData.deliveryAddress;
      const validatedAddress = !!(firstLine && city && postalcode);
      if (this.formData.billingSameAsShipping) {
        return validatedAddress;
      }
      const {
        firstLine: firstLineBilling,
        city: cityBilling,
        postalcode: postalcodeBilling
      } = this.formData.billingAddress;
      const validatedBillingAddress = !!(firstLineBilling && cityBilling && postalcodeBilling);
      return validatedAddress && validatedBillingAddress;
    },
    validateCheckoutData() {
      if (!this.validateContactInformation() || !this.validateAddress()) {
        throw new CheckoutDataError("Invalid address or contact information");
      }
    },
    submit() {
      this.validateCheckoutData();
      this.$refs.stripeForm.submit();
    },
    async onConfirmCardSetup(response: any) {
      try {
        if (response.setupIntent) {
          await PaymentAPI.postPaymentToken(
            response.setupIntent.payment_method,
            this.customerId,
            this.petId
          );
          this.$emit("onSuccess", response);
        } else if (response.error) {
          this.$emit("onError", {
            message: response.error.message,
            source: "stripe"
          });
        }
      } catch (error) {
        this.$emit("onError", {
          message: error.errors || error.message,
          source: "vet2pet"
        });
      }
    }
  },
  computed: {
    contactInfoDisplay() {
      const { firstName, lastName, email, phoneNumber } = this.formData;
      return `${firstName} ${lastName} (${email}, ${phoneNumber})`;
    },
    deliveryAddressDisplay() {
      const { firstLine, secondLine, city, postalcode } = this.formData.deliveryAddress;
      return secondLine
        ? `${firstLine}, ${secondLine}, ${city}, ${postalcode}`
        : `${firstLine}, ${city}, ${postalcode}`;
    },
    billingAddressDisplay() {
      const { firstLine, secondLine, city, postalcode } = this.formData.billingAddress;
      return secondLine
        ? `${firstLine}, ${secondLine}, ${city}, ${postalcode}`
        : `${firstLine}, ${city}, ${postalcode}`;
    }
  },
  async mounted() {
    this.loading = true;
    await this.getContactDetails();
    this.loading = false;
  }
});
