
import Vue, { PropType } from "vue";
import DailyCalories, {
  DailyCaloriesProps
} from "@/components/v2/FeedingPlan/FeedingPlanDetails/DailyCalories.vue";
import ScoopSettings from "@/components/v2/FeedingPlan/FeedingPlanDetails/ScoopSettings.vue";
import type { FeedingGuideDTO, BlendProductDTO } from "@/api/v2/feeding-plan";

export default Vue.extend({
  name: "FeedingPlan",
  components: { DailyCalories, ScoopSettings },
  props: {
    blendProduct: { type: Object as PropType<BlendProductDTO> },
    dailyCalories: { type: Object as PropType<DailyCaloriesProps> },
    feedingGuide: { type: Object as PropType<FeedingGuideDTO> },
    petName: String,
    editable: {type: Boolean, default: false}
  }
});
