
import Vue from "vue";
import {
  CustomerDetailsErrors,
  CustomerDetailsForm,
  mapAPIErrorsToViewModel,
  mapCustomerDetailsFormToAPIPayload,
  mapCustomerDetailsPayloadToViewModel
} from "@/components/CustomerDetails/CustomerDetails.vm";
import { ApiInterface } from "@/api/api.interface";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import customerService from "@/services/customer/customer.service";
import ChoiceSelector from "@/components/ChoiceSelector.vue";

type Data = {
  submitDisabled: boolean;
  Customer: CustomerDetailsForm;
  customerId: null | number;
  errors: CustomerDetailsErrors;
};

type Inject = {
  Api: ApiInterface;
};

export default Vue.extend<Data, any, any, any & Inject>({
  name: "CustomerDetailsForm",
  components: { ChoiceSelector, WithOldVetBar },
  inject: ["Api"],
  data() {
    return {
      submitDisabled: false,
      Customer: {
        billingAddress: {},
        billingAddressSameAsShipping: "true",
        contactConsent: null,
        deliveryAddress: {},
        email: "",
        first: "",
        last: "",
        mobile: "",
        password: "Asdf@qwer#1234",
        customerId: +this.customerId
      } as CustomerDetailsForm,
      customerId: null,
      errors: {} as CustomerDetailsErrors
    };
  },
  computed: {
    communicationPreferencesSelected() {
      return this.Customer.contactConsent !== null;
    }
  },
  async mounted() {
    this.customerId = this.$route.params.customer_id;
    await this.loadCustomerDetails(this.customerId);
  },
  methods: {
    async loadCustomerDetails(customerId: number) {
      const response = await customerService.getCustomerContactDetails(customerId);
      this.Customer = mapCustomerDetailsPayloadToViewModel(response);
      // the below fields don't come from the API so have to be set again
      this.Customer.petId = +this.$route.params.pet_id;
      this.Customer.password = "Asdf@qwer#1234";
    },
    async updateCustomerDetails(event: Event) {
      event.preventDefault();
      this.submitDisabled = true;
      this.errors = {};
      const payload = Object.assign({}, this.Customer);
      if (payload.billingAddressSameAsShipping === "true") {
        delete payload.billingAddress;
      }
      const response = await this.Api.updateCustomer(
        +this.customerId,
        mapCustomerDetailsFormToAPIPayload(payload)
      );
      if (response.success) {
        this.customerId = response.customer.customer_id;
        await this.$router.push({
          name: "payment-details",
          params: { customer_id: this.customerId, pet_id: +this.$route.params.pet_id }
        });
      } else {
        this.submitDisabled = false;
        this.errors = mapAPIErrorsToViewModel(response.errors);
      }
    }
  }
});
