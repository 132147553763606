
import Vue, { PropType } from "vue";

export interface FeedingPlanPriceBreakdown {
  days: number;
  total: number;
  totalBeforeDiscount?: number;
  totalPricePerDay: number;
}

export default Vue.extend({
  name: "FeedingPlanPrice",
  props: {
    priceBreakdown: {
      type: Object as PropType<FeedingPlanPriceBreakdown>
    },
    btnText: {
      type: String,
      default: "CHECKOUT"
    },
    hideAction: Boolean,
    disableButton: Boolean
  },
  computed: {
    showDiscount(): boolean {
      if (this.priceBreakdown?.totalBeforeDiscount) {
        return this.priceBreakdown.total < this.priceBreakdown?.totalBeforeDiscount;
      }
      return false;
    },
    pricePerDayBeforeDiscount(): number {
      if (this.priceBreakdown?.totalBeforeDiscount) {
        return this.priceBreakdown.totalBeforeDiscount / this.priceBreakdown.days;
      }
      return this.priceBreakdown?.totalPricePerDay || 0;
    }
  },
  methods: {
    onClick() {
      this.$emit("onClick");
    }
  }
});
