
import Vue, { PropType } from "vue";
import { ProductDTO, SubscriptionProductDTO } from "@/services/product.service";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export type ProductCheckbox = {
  checked: boolean;
  identifier: string;
};

export default Vue.extend<any, any, any, any>({
  name: "SubscriptionSingleProductCheckbox",
  components: { LoadingSpinner },
  props: {
    error: String,
    product: Object as () => ProductDTO,
    subscriptionProducts: {
      type: Array as PropType<SubscriptionProductDTO[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subscriptionProductsIdentifiers() {
      return this.subscriptionProducts.map(
        (product: SubscriptionProductDTO) => product.productIdentifier
      );
    }
  },
  methods: {
    onChange(event: any, identifier: string) {
      this.$emit("change", {
        checked: event.target.checked,
        identifier: identifier
      } as ProductCheckbox);
    }
  }
});
