
import Vue from "vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PaginationControls from "@/components/PaginationControls.vue";
import conditionRecordService from "@/services/conditionRecord.service";
import { HistoricalConditionRecord } from "@/api/api.dto";
import Pagination from "@/api/pagination.dto";
import { mapAgeToText } from "@/components/common.vm";

type ConditionRecordsDisplay = {
  date: string;
  age: string;
  weight: string;
  bodyConditionScore: string;
};

export default Vue.extend<any, any, any, any>({
  name: "ConditionRecords",
  components: {
    WithOldVetBar,
    LoadingSpinner,
    PaginationControls
  },
  data() {
    return {
      conditionRecords: [] as Array<ConditionRecordsDisplay>,
      pagination: new Pagination(),
      itemsPerPage: 10,
      isLoading: true,
      errorMessage: ""
    };
  },
  async mounted() {
    await this.loadConditionRecords();
  },
  methods: {
    toNutritionalPlan() {
      this.$router.push({
        name: this.routeNames.NUTRITIONAL_PLAN,
        params: { pet_id: +this.$route.params.pet_id, customer_id: +this.$route.params.customer_id }
      });
    },
    async pageChange(page: number) {
      await this.$router.replace({
        name: this.routeNames.CONDITION_HISTORY,
        query: { page: page }
      });
      await this.loadConditionRecords();
    },
    async loadConditionRecords() {
      this.isLoading = true;

      const petId = +this.$route.params.pet_id;
      const currentPage = this.$route.query.page ? +this.$route.query.page : 1;
      const conditionRecordsResponse = await conditionRecordService.getPetConditionRecords(
        petId,
        currentPage,
        this.itemsPerPage
      );
      if (conditionRecordsResponse.success) {
        this.conditionRecords = this.createConditionRecordsDisplay(
          conditionRecordsResponse.condition_records
        );
        this.pagination = new Pagination({}, conditionRecordsResponse.pagination);
      } else {
        this.showError = true;
        this.errorMessage = "Could not receive historical condition records";
      }

      this.isLoading = false;
    },
    createConditionRecordsDisplay(
      conditionRecords: Array<HistoricalConditionRecord>
    ): Array<ConditionRecordsDisplay> {
      return conditionRecords.map(record => {
        return {
          date: this.dateDisplay(record.created),
          age: this.mapAgeToText(record.pet_age_at_record_creation),
          weight: `${record.weight} kg`,
          bodyConditionScore: `${record.condition_id} - ${record.name}`
        };
      });
    },
    dateDisplay(date: Date | string) {
      const displayDate = new Date(date);
      // custom format to avoid swapping months and days by local settings
      const day = (displayDate.getDate() < 10 ? "0" : "") + displayDate.getDate();
      const month = (displayDate.getMonth() + 1 < 10 ? "0" : "") + (displayDate.getMonth() + 1);
      const dateString = `${day}/${month}/${displayDate.getFullYear()}`;

      const hours = (displayDate.getHours() < 10 ? "0" : "") + displayDate.getHours();
      const minutes = (displayDate.getMinutes() < 10 ? "0" : "") + displayDate.getMinutes();
      const timeString = `${hours}:${minutes}`;

      return `${dateString}, ${timeString}`;
    },
    mapAgeToText
  }
});
