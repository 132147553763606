
/*
Controls the rendering of the Payment component. On mounted, it first
tries to fetch the client secret from the API. While it's waiting for
the response, it displays a spinner. If the request to fetch the client
secret fails, it displays an error message and a `retry` button, inviting
the user to click the button. When clicking the button, this wrapper tries
to fetch the client secret again. Once the client secret is available, the
Payment component is loaded.
The Payment component considers 2 types of errors:

1) Card validation errors that are raised by Stripe at the time of typing
the card. These errors are displayed to the user directly in the Payment
component.

2) API errors resulting from sending the card details to Stripe or from
sending the temporary token to the API. Such errors trigger the error flow
from this component, when that happens:

2.1 The error message together with the `retry` button will appear in this
component.

2.2. The client secret will be dropped.

2.3. The payment form will disappear.

These is to ensure that a new payment form with a newly generated client
secret is loaded for a fresh start. This is to avoid reuse of the same token,
which would result in yet another error.
 */
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";
import Payment from "@/components/Payment/Payment.vue";

type Data = {
  clientSecret: null;
  displayErrorRetry: boolean;
  errorMessage: string;
  displaySpinner: boolean;
};

type Inject = {
  Api: ApiInterface;
};

export default Vue.extend<Data, any, any, any & Inject>({
  name: "PaymentWrapper",
  inject: ["Api"],
  props: ["customerId", "petId"],
  components: {
    Payment
  },
  data() {
    return {
      clientSecret: null,
      displayErrorRetry: false,
      errorMessage:
        "There was an error. Please click retry. " +
        "If the problem persists, please contact customer support.",
      displaySpinner: true
    };
  },
  methods: {
    async fetchPaymentClientSecret() {
      this.shouldDisplaySpinner(true);
      const response = await this.Api.fetchPaymentClientSecret();
      if (response.success) {
        this.clientSecret = response.clientSecret;
        this.displayErrorRetry = false;
      } else {
        this.doDisplayErrorRetry();
      }
      this.shouldDisplaySpinner(false);
    },
    doDisplayErrorRetry() {
      this.displayErrorRetry = true;
      this.clientSecret = null;
    },
    shouldDisplaySpinner(display: boolean) {
      this.displaySpinner = display;
    }
  },
  async mounted() {
    await this.fetchPaymentClientSecret();
  }
});
