
import Vue, { PropType } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import productService, {
  ProductDescriptionDTO,
  ProductDTO,
  SubscriptionProductDTO
} from "@/services/product.service";

export default Vue.extend<any, any, any, any>({
  name: "FortifloraProductSelector",
  components: { LoadingSpinner },
  data() {
    return {
      isProductActive: false,
      isLoading: false,
      isProductSubscriptionLoading: false,
      tabModel: null,
      mainSection: { html: "", order: 0 } as ProductDescriptionDTO,
      otherSections: []
    };
  },
  props: {
    fortifloraProduct: {
      type: Object as PropType<ProductDTO>,
      required: true
    },
    subscriptionId: {
      type: Number,
      required: true
    },
    selectedProducts: {
      type: Array as PropType<SubscriptionProductDTO[]>,
      default: []
    }
  },
  methods: {
    setParsedDescription() {
      const sections = this.fortifloraProduct.descriptionSections;
      if (!sections) {
        return;
      }
      const mainSection = sections.find((item: ProductDescriptionDTO) => !item.section);
      const sortedSections = sections
        .filter((item: ProductDescriptionDTO) => item.section)
        .sort((a: any, b: any) => (a.order > b.order ? 1 : -1));

      this.mainSection = mainSection || {
        html: this.fortifloraProduct.description,
        order: 0
      };
      this.otherSections = sortedSections;
    },
    async addProductToSubscription() {
      this.isProductSubscriptionLoading = true;
      const result = await productService.addToSubscription(
        this.subscriptionId,
        this.fortifloraProduct.productIdentifier
      );
      this.isProductSubscriptionLoading = false;
      this.isProductActive = result.success;
      this.$emit("change", { success: result.success });
    },
    async removeProductFromSubscription() {
      this.isProductSubscriptionLoading = true;
      const result = await productService.removeFromSubscription(
        this.subscriptionId,
        this.fortifloraProduct.productIdentifier
      );
      this.isProductSubscriptionLoading = false;
      this.isProductActive = !result.success;
      this.$emit("change", { success: result.success });
    }
  },
  mounted() {
    this.isProductActive = this.selectedProducts?.some(
      (product: SubscriptionProductDTO) =>
        product.productIdentifier === this.fortifloraProduct.productIdentifier
    );
    this.setParsedDescription();
  }
});
