
import Vue from "vue";
import { CompositionDTO } from "@/api/v2/feeding-plan";

export default Vue.extend({
  name: "Composition",
  props: {
    composition: CompositionDTO
  }
});
