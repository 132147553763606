
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";
import { SubscriptionStatus } from "@/api/api.dto";
import {
  CHECKOUT_COMPLETE,
  ConsultationModes,
  CREATE_NEW,
  DASHBOARD_ACTIONS,
  DashboardAction,
  DashboardActionOptions,
  EDIT_ACTIVE,
  EDIT_INCOMPLETE,
  REACTIVATE_SUBSCRIPTION,
  SearchResultData,
  SEE_CUSTOMER_DETAILS,
  SubscriptionStatusVM
} from "@/components/Dashboard/Dashboard.vm";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { ACTIONS } from "@/store";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import Customer from "@/models/customer.model";
import customerService from "@/services/customer/customer.service";
import UpdateWeight from "@/components/Dashboard/UpdateWeight.vue";

type Data = {
  pets: SearchResultData[];
  query: string;
  searched: boolean;
  recent: boolean;
  errorBoxText?: string;
  searchWithInactive: boolean;
  isLoading: boolean;
  customers: Array<Customer>;
  filterProfileStatus: any;
  selectedFilter: string;
  pagination: any;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  startPetCreationFlow(): Promise<void>;
  searchPets(page?: number): Promise<void>;
  showRecent(): Promise<void>;
  onSearchTextChange(): Promise<void>;
  prettyPrintDate(date: number): string;
  mapSubscriptionStatus(status: string): SubscriptionStatusVM;
  applyAction(actionName: DashboardAction, options: DashboardActionOptions): Promise<void>;
  resetErrorBoxText(): void;
  toggleLoading(flag: boolean): void;
  nextPage(): Promise<void>;
  previousPage(): Promise<void>;
};

type Computed = {};

type Props = any;

export default Vue.extend<Data, Methods, Computed, Props & Inject>({
  name: "Dashboard",
  inject: ["Api"],
  components: { UpdateWeight, LoadingSpinner, WithOldVetBar },
  data() {
    return {
      pets: [],
      customers: [],
      searched: false,
      recent: false,
      query: "",
      searchWithInactive: false,
      errorBoxText: "",
      isLoading: false,
      filterProfileStatus: [
        {
          text: "All feeding plans",
          value: ""
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Ready for checkout",
          value: "complete"
        },
        {
          text: "Incomplete",
          value: "pending"
        },
        {
          text: "Paused",
          value: "paused"
        },
        {
          text: "Cancelled",
          value: "cancelled"
        }
      ],
      selectedFilter: "",
      pagination: {}
    };
  },
  computed: {
    CTAText(): string {
      return "Add new customer";
    }
  },
  methods: {
    toggleLoading(flag: boolean): void {
      this.isLoading = flag;
    },
    async startPetCreationFlow(): Promise<void> {
      await this.applyAction(CREATE_NEW, { petId: 0, customerId: 0 });
    },
    resetErrorBoxText(): void {
      this.errorBoxText = "";
    },
    async searchPets(page = 1): Promise<void> {
      this.toggleLoading(true);
      this.resetErrorBoxText();
      this.searched = this.query.length > 0;

      const customersResponse = await customerService.getCustomers(
        this.query,
        this.selectedFilter,
        page
      );
      this.customers = customersResponse.customers;
      this.pagination = customersResponse.pagination;

      this.toggleLoading(false);
    },
    async nextPage(): Promise<void> {
      await this.searchPets(this.pagination.page + 1);
    },
    async previousPage(): Promise<void> {
      await this.searchPets(this.pagination.page - 1);
    },
    async showRecent(): Promise<void> {
      this.query = "";
      this.recent = !this.recent;
      await this.searchPets();
    },
    mapSubscriptionStatus(status: string | undefined): SubscriptionStatusVM {
      const statusText = status || "";
      switch (statusText.toLowerCase()) {
        case SubscriptionStatus.PENDING:
          return {
            displayStatus: "Incomplete",
            actions: [EDIT_INCOMPLETE]
          };
        case SubscriptionStatus.COMPLETE:
          return {
            displayStatus: "Ready for checkout",
            actions: [CHECKOUT_COMPLETE]
          };
        case SubscriptionStatus.ACTIVE:
          return {
            displayStatus: "Active",
            actions: [EDIT_ACTIVE, SEE_CUSTOMER_DETAILS]
          };
        case SubscriptionStatus.CANCELLED:
          return {
            displayStatus: "Cancelled",
            actions: [REACTIVATE_SUBSCRIPTION]
          };
        case SubscriptionStatus.PAUSED:
          return {
            displayStatus: "Paused",
            actions: []
          };
        default:
          return {
            displayStatus: "",
            actions: []
          };
      }
    },
    async applyAction(action: DashboardAction, options: DashboardActionOptions) {
      if (action.actionName === DASHBOARD_ACTIONS.VIEW_OR_EDIT_ACTIVE) {
        this.$store.dispatch(ACTIONS.SET_CONSULTATION_MODE, ConsultationModes.VIEW_OR_EDIT_ACTIVE);
        await this.$router.push({
          name: action.nextRoute,
          params: { pet_id: options.petId.toString(), customer_id: options.customerId.toString() }
        });
        return;
      }
      if (action.actionName === DASHBOARD_ACTIONS.CREATE_NEW) {
        await this.$router.push({ name: action.nextRoute });
        return;
      }
      if (action.actionName === DASHBOARD_ACTIONS.EDIT_INCOMPLETE) {
        const next = this.$store.getters.vetVuetifyViews
          ? this.prototypeRouteNames.BLEND_CONFIGURATION
          : this.routeNames.PET_INFORMATION;

        await this.$router.push({
          name: next,
          params: { pet_id: options.petId.toString(), customer_id: options.customerId.toString() }
        });
        return;
      }
      if (action.actionName === DASHBOARD_ACTIONS.REACTIVATE_SUBSCRIPTION) {
        await this.$router.push({
          name: action.nextRoute,
          params: { pet_id: options.petId.toString(), customer_id: options.customerId.toString() }
        });
      } else {
        await this.$router.push({
          name: action.nextRoute,
          params: { pet_id: options.petId.toString(), customer_id: options.customerId.toString() }
        });
      }
    }
  },
  async mounted(): Promise<void> {
    this.$store.dispatch(ACTIONS.SET_CONSULTATION_MODE, null);
    await this.showRecent();
  }
});
