import { EnvironmentDetectionService } from "@/services/environmentDetection.service";

const PROD_PUBLIC_STRIPE_KEY = "pk_live_eaeccAhmbKesuJbGLaNxxeey00EIDN0B2f";
const TEST_PUBLIC_STRIPE_KEY = "pk_test_aXGXm8VwFoFy4QRKwPJjJ6iz00rD5JQ6Ic";

const PROD_PUBLIC_HEAP_KEY = "3152668026";
const TEST_PUBLIC_HEAP_KEY = "930772450";

export class EnvironmentVariables {
  static stripePublicKey(): string {
    return EnvironmentDetectionService.isProduction(window.location.hostname)
      ? PROD_PUBLIC_STRIPE_KEY
      : TEST_PUBLIC_STRIPE_KEY;
  }
  static heapPublicKey(): string {
    return EnvironmentDetectionService.isProduction(window.location.hostname)
      ? PROD_PUBLIC_HEAP_KEY
      : TEST_PUBLIC_HEAP_KEY;
  }
}
