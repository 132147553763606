import axios from "axios";

export class AddressDTO {
  constructor(
    public firstLine: string,
    public city: string,
    public postalCode: string,
    public secondLine?: string,
    public thirdLine?: string,
    public county?: string
  ) {}

  public static buildFromJson(json: any): AddressDTO {
    return new AddressDTO(
      json.first_line,
      json.city,
      json.postal_code,
      json.second_line,
      json.third_line,
      json.county
    );
  }

  toJson() {
    return {
      first_line: this.firstLine,
      second_line: this.secondLine,
      third_line: this.thirdLine,
      city: this.city,
      postal_code: this.postalCode,
      county: this.county
    };
  }
}

export class CustomerDetailsDTO {
  constructor(
    public customerId: number,
    public billingSameAsShipping: boolean,
    public contactConsent: boolean,
    public email: string,
    public first: string,
    public last: string,
    public mobilePhone: string,
    public dataCaptureConsent: boolean,
    public petHealthClubMember: boolean,
    public deliveryAddress?: AddressDTO,
    public billingAddress?: AddressDTO
  ) {}

  public static buildFromJson(json: any): CustomerDetailsDTO {
    return new CustomerDetailsDTO(
      json.customer_id,
      json.billing_same_as_shipping,
      json.contact_consent,
      json.email,
      json.first,
      json.last,
      json.mobile_phone,
      json.data_capture_consent,
      json.pet_health_club_member,
      json.delivery_address ? AddressDTO.buildFromJson(json.delivery_address) : undefined,
      json.billing_address ? AddressDTO.buildFromJson(json.billing_address) : undefined
    );
  }
}

export class CustomerDetailsUpdateDTO {
  constructor(
    public email: string,
    public first: string,
    public last: string,
    public mobilePhone: string,
    public contactConsent: boolean,
    public billingSameAsShipping: boolean,
    public deliveryAddress?: AddressDTO,
    public billingAddress?: AddressDTO
  ) {}

  toJson() {
    return {
      email: this.email,
      first: this.first,
      last: this.last,
      billing_same_as_shipping: this.billingSameAsShipping,
      contact_consent: this.contactConsent,
      mobile_phone: this.mobilePhone,
      delivery_address: this.deliveryAddress ? this.deliveryAddress.toJson() : undefined,
      billing_address: this.billingAddress ? this.billingAddress.toJson() : undefined
    };
  }
}

export interface OOCCResponse {
  success: boolean;
  errorMessage?: string;
}

export class CustomerAPI {
  private static readonly baseUrl = "/api/vets/v1/customer/";

  static async getContactDetails(customerId: number): Promise<CustomerDetailsDTO> {
    const response = await axios.get(`${this.baseUrl}${customerId}/contact-details`);
    return CustomerDetailsDTO.buildFromJson(response.data.resp);
  }
  static putCheckoutData(customerId: number, data: CustomerDetailsUpdateDTO): Promise<any> {
    return axios.put(`${this.baseUrl}${customerId}/checkout`, data.toJson());
  }

  static async initOutOfClinicCheckout(petId: number): Promise<OOCCResponse> {
    try {
      const result = { success: false } as OOCCResponse;
      const response = await axios.post(`${this.baseUrl}init-out-of-clinic-checkout`, {
        pet_id: petId
      });
      result.success = response.data.resp.success;
      if (response.data.resp.errorMessage) {
        result.errorMessage = response.data.resp.errorMessage;
      }
      return result;
    } catch (error) {
      return { success: false } as OOCCResponse;
    }
  }
}
