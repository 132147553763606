<template>
  <PortionAllocationEditPage v-if="!vetVuetifyViews" />
  <EditPortion v-else />
</template>

<script>
import { mapGetters } from "vuex";
import PortionAllocationEditPage from "@/components/PortionAllocation/PortionAllocationEditPage.vue";
import EditPortion from "@/views/v2/EditPortion.vue";

export default {
  name: "EditPortionPage",
  components: { EditPortion, PortionAllocationEditPage },
  computed: {
    ...mapGetters(["vetVuetifyViews"])
  }
};
</script>

<style scoped></style>
