
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ActivateSubscription",
  inject: ["Api"],
  props: {
    petName: {
      type: String,
      required: true
    },
    petId: {
      type: Number,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onActivate() {
      await this.Api.activateSubscription(this.petId);
      await this.$router.push({ name: "thank-you" });
    }
  }
});
