import {
  GenericPayloadAttributeErrors,
  CustomerDetailsRequestPayload,
  CustomerDetailsResponse
} from "@/api/api.dto";

type Address = {
  city: string;
  county?: string;
  firstLine: string;
  postalCode: string;
  secondLine?: string;
  thirdLine?: string;
};

export type CustomerDetailsForm = {
  billingAddress?: Address;
  billingAddressSameAsShipping: string;
  contactConsent: string | null;
  deliveryAddress: Address;
  customerId: number;
  email: string;
  first: string;
  last: string;
  mobile: string;
  password: string;
};

type AddressErrors = Address & {
  generic: string;
};

export type CustomerDetailsErrors = {
  billingAddress?: AddressErrors;
  billingAddressSameAsShipping?: string;
  contactConsent?: string | null;
  deliveryAddress?: AddressErrors;
  email?: string;
  first?: string;
  last?: string;
  mobile?: string;
  password?: string;
};

function addressMapperPayloadToViewModel(addressPayload: any): any {
  // Optional fields are not always present in the payload. Sometimes
  // the API sends these fields set to `null`. To guard against these
  // cases, we are setting optional fields to undefined by default so
  // that they're automatically removed by axios when sending the
  // payload back to the server if they don't exist. This way we avoid
  // API validation errors
  const address = {
    city: addressPayload.city,
    county: addressPayload.county || undefined,
    firstLine: addressPayload.first_line,
    postalCode: addressPayload.postal_code,
    secondLine: addressPayload.second_line || undefined,
    thirdLine: addressPayload.third_line || undefined
  } as any;
  if (addressPayload.company) {
    address.company = addressPayload.company;
  }
  if (address.delivery_instructions_other) {
    address.deliveryInstructionsOther = address.delivery_instructions_other;
  }
  return address;
}

export function mapCustomerDetailsPayloadToViewModel(
  customerDetails: CustomerDetailsResponse
): CustomerDetailsForm {
  const details = {
    billingAddressSameAsShipping: !customerDetails.billing_same_as_shipping ? "false" : "true",
    contactConsent: !customerDetails.contact_consent ? "no" : "yes",
    deliveryAddress: customerDetails.delivery_address
      ? addressMapperPayloadToViewModel(customerDetails.delivery_address)
      : {},
    customerId: customerDetails.customer_id,
    email: customerDetails.email,
    first: customerDetails.first,
    last: customerDetails.last,
    mobile: customerDetails.mobile_phone
  } as CustomerDetailsForm;
  if (customerDetails.billing_address) {
    details.billingAddress = addressMapperPayloadToViewModel(customerDetails.billing_address);
  } else {
    details.billingAddress = {} as Address;
  }
  return details;
}

function addressMapperViewModelToPayload(address: any): any {
  // When a user types a value for an optional field and later clears
  // the input, the field remains set to an empty string in the Vue
  // model data. Such values have to be removed in order to prevent
  // inconsistent interactions with the API. We are setting these values
  // to undefined so that they're automatically removed by axios when
  // sending the payload back to the server. This way we avoid API validation
  // errors
  const payload = {
    city: address.city,
    county: address.county || undefined,
    first_line: address.firstLine,
    postal_code: address.postalCode,
    second_line: address.secondLine || undefined,
    third_line: address.thirdLine || undefined
  } as any;
  // Address Delivery specific attributes
  if (address.company) {
    payload.company = address.company;
  }
  if (address.deliveryInstructionOther) {
    payload.delivery_instruction_other = address.deliveryInstructionOther;
  }
  return payload;
}

export function mapCustomerDetailsFormToAPIPayload(
  customerDetailsForm: CustomerDetailsForm
): CustomerDetailsRequestPayload {
  const payload = {
    billing_same_as_shipping: customerDetailsForm.billingAddressSameAsShipping === "true",
    contact_consent: customerDetailsForm.contactConsent === "yes",
    delivery_address: addressMapperViewModelToPayload(customerDetailsForm.deliveryAddress),
    email: customerDetailsForm.email,
    first: customerDetailsForm.first,
    last: customerDetailsForm.last,
    mobile_phone: customerDetailsForm.mobile,
    password: customerDetailsForm.password
  } as CustomerDetailsRequestPayload;
  if (customerDetailsForm.billingAddress) {
    payload.billing_address = addressMapperViewModelToPayload(customerDetailsForm.billingAddress);
  }
  return payload;
}

export function mapAPIErrorsToViewModel(
  errors: GenericPayloadAttributeErrors[]
): CustomerDetailsErrors {
  const customerErrors = {
    billingAddress: {},
    deliveryAddress: {}
  } as CustomerDetailsErrors;
  const attributeErrors = errors.filter(
    error => error.source && error.source && error.source && error.source
  );
  // eslint-disable-next-line
  for (const error of attributeErrors) {
    if (error.source && error.source === "billing_same_as_shipping") {
      customerErrors.billingAddressSameAsShipping = error.detail;
    }
    if (error.source && error.source === "contact_consent") {
      customerErrors.contactConsent = error.detail;
    }
    if (error.source && error.source === "email") {
      customerErrors.email = error.detail;
    }
    if (error.source && error.source === "first") {
      customerErrors.first = error.detail;
    }
    if (error.source && error.source === "last") {
      customerErrors.last = error.detail;
    }
    if (error.source && error.source === "mobile_phone") {
      customerErrors.mobile = error.detail;
    }
    if (error.source && error.source === "delivery_address") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.generic = error.detail;
    }
    if (error.source && error.source === "delivery_address:first_line") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.firstLine = error.detail;
    }
    if (error.source && error.source === "delivery_address:second_line") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.secondLine = error.detail;
    }
    if (error.source && error.source === "delivery_address:third_line") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.thirdLine = error.detail;
    }
    if (error.source && error.source === "delivery_address:city") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.city = error.detail;
    }
    if (error.source && error.source === "delivery_address:county") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.county = error.detail;
    }
    if (error.source && error.source === "delivery_address:postal_code") {
      if (!customerErrors.deliveryAddress) {
        customerErrors.deliveryAddress = {} as AddressErrors;
      }
      customerErrors.deliveryAddress.postalCode = error.detail;
    }
    if (error.source && error.source === "billing_address") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.generic = error.detail;
    }
    if (error.source && error.source === "billing_address:first_line") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.firstLine = error.detail;
    }
    if (error.source && error.source === "billing_address:second_line") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.secondLine = error.detail;
    }
    if (error.source && error.source === "billing_address:third_line") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.thirdLine = error.detail;
    }
    if (error.source && error.source === "billing_address:city") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.city = error.detail;
    }
    if (error.source && error.source === "billing_address:county") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.county = error.detail;
    }
    if (error.source && error.source === "billing_address:postal_code") {
      if (!customerErrors.billingAddress) {
        customerErrors.billingAddress = {} as AddressErrors;
      }
      customerErrors.billingAddress.postalCode = error.detail;
    }
  }
  return customerErrors;
}
