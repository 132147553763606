
import Vue from "vue";
import PortionSliderVisualization from "@/components/PortionAllocation/PortionSliderVisualization.vue";
import subscriptionService from "@/services/subscription.service";
import BrandModal from "@/components/BrandModal/BrandModal.vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import round from "@/utils/round.function";
import { mapGetters } from "vuex";
import EditPortion from "@/views/v2/EditPortion.vue";

enum presentationEnum {
  PERCENTAGE,
  KCAL
}

export default Vue.extend<any, any, any, any>({
  name: "PortionAllocationEditPage",
  components: { EditPortion, WithOldVetBar, PortionSliderVisualization, BrandModal },
  data() {
    return {
      presentationEnum: presentationEnum,
      showIn: presentationEnum.PERCENTAGE,
      targetDailyKcal: 0,
      otherFoodPercentage: 0,
      showEditProfileModal: false,
      calorieGuideTableItems: [
        { name: "30g Piece of cheese", kcalValue: 170 },
        { name: "Medium dental chew", kcalValue: 69 },
        { name: "50g Sausage", kcalValue: 150 },
        { name: "1 Digestive biscuit", kcalValue: 70 },
        { name: "½ Apple", kcalValue: 20 },
        { name: "½ Slice white buttered toast", kcalValue: 80 },
        { name: "25g Ham", kcalValue: 40 },
        { name: "28g Lean roast beef", kcalValue: 50 },
        { name: "20g Chicken", kcalValue: 30 }
      ]
    };
  },
  computed: {
    ...mapGetters(["originalDailyKcalRequirement", "totalDailyKcalRequirement"]),
    feedingPlan() {
      return this.$store.state.feedingPlan;
    },
    petDetails() {
      return this.$store.state.petDetails;
    },
    dailyDryKcalPercentage() {
      return 100 - this.otherFoodPercentage;
    },
    kcalMultiplier() {
      return this.targetDailyKcal / this.originalDailyKcalRequirement;
    }
  },
  mounted() {
    this.otherFoodPercentage = 100 - this.feedingPlan.targetKcalPercent;
    this.targetDailyKcal = this.totalDailyKcalRequirement;
  },
  methods: {
    pluralize(count: number, noun: string): string {
      return `${count} ${noun}${count !== 1 ? "s" : ""}`;
    },
    onKcalChange(event: any) {
      const value = event.target.value;
      this.otherFoodPercentage =
        ((this.totalDailyKcalRequirement - value) / this.totalDailyKcalRequirement) * 100;
    },
    addKcal() {
      this.targetDailyKcal += this.originalDailyKcalRequirement * 0.1;
    },
    removeKcal() {
      this.targetDailyKcal -= this.originalDailyKcalRequirement * 0.1;
    },
    handleResetTargetKcal() {
      this.targetDailyKcal = this.originalDailyKcalRequirement;
    },
    save() {
      const petId = this.petDetails.petId;
      subscriptionService
        .changeKcalTarget({
          pet_id: petId,
          target_daily_kcal: this.targetDailyKcal,
          target_kcal_multiplier: this.kcalMultiplier,
          other_kcal_percent: this.otherFoodPercentage
        })
        .then(() => {
          this.$router.push({
            name: "nutritional-plan",
            params: { pet_id: this.petDetails.petId }
          });
        });
    },
    submitChange() {
      if (this.petDetails.subscriptionStatus === "active") {
        this.showEditProfileModal = true;
      } else {
        this.save();
      }
    },
    calculateDailyEnergyRequirement(treatKcal: number, totalKcal: number) {
      return Math.round((treatKcal / totalKcal) * 100);
    }
  },
  filters: {
    mgToKg(mg: number): number {
      return mg / 1000;
    },
    round: round
  }
});
