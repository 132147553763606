
import Vue from "vue";
import {
  FeedingPlanVM,
  mapNutritionalPlanToViewModel,
  PetDetailsVM,
  RecommendedDiet
} from "@/components/NutritionalPlan/NutritionalPlan.vm";
import ActivateSubscription from "@/components/NutritionalPlan/ActivateSubscription.vue";
import SubscriptionProductsSelector from "@/components/SubscriptionProducts/SubscriptionProductsSelector.vue";
import { GetPetByIdResponse } from "@/api/api.dto";
import { ApiInterface } from "@/api/api.interface";
import { mapPetDetailsResponseToViewModel, PetDetails } from "@/components/common.vm";
import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import BrandModal from "@/components/BrandModal/BrandModal.vue";
import PortionAllocationComponent from "@/components/PortionAllocation/PortionAllocationComponent.vue";
import { FeatureFlags } from "@/api/featureFlags/featureFlags.api";
import { mapCustomerDetailsPayloadToViewModel } from "@/components/CustomerDetails/CustomerDetails.vm";
import store, { ACTIONS } from "@/store";
import { TherapeuticSelectableHealthIssuesService } from "@/services/therapeuticSelectableHealthIssues.service";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import { capitalize } from "@/utils/capitalize.function";
import feedingPlanService, {
  FeedingPlanData,
  FeedingPlanHistoryDate
} from "@/services/feedingPlan.service";
import HistoricalNutritionalPlan from "@/components/NutritionalPlan/HistoricalNutritionalPlan.vue";
import PriceSummaryTable from "@/components/SubscriptionBreakdown/PriceSummaryTable.vue";
import PriceBreakdown from "@/models/price-breakdown.model";
import productService, { ProductDTO, SubscriptionProductDTO } from "@/services/product.service";
import priceBreakdownService from "@/services/priceBreakdown.service";
import ReactivateSubscription from "@/components/NutritionalPlan/ReactivateSubscription.vue";
import FortifloraProductSelector from "@/components/SubscriptionProducts/FortifloraProductSelector.vue";
import FeedingPlan from "@/views/v2/FeedingPlan.vue";

enum RegistrationEmailModalStateEnum {
  NONE,
  SUCCESS,
  ERROR
}

type Data = {
  submitDisabled: boolean;
  petId: number;
  petDetails: PetDetails;
  feedingPlan: RecommendedDiet;
  subscriptionId: number | null;
  reproductiveStatus: string;
  isViewOrEditActiveMode: boolean;
  isCompleteSubscription: boolean;
  isCancelledSubscription: boolean;
  showEditProfileModal?: boolean;
  showEditDietModal?: boolean;
  customer?: any;
  customerStatus?: any;
  showNutritionalPlanErrorModal: boolean;
  registrationEmailModalStates: typeof RegistrationEmailModalStateEnum;
  registrationEmailModalState: RegistrationEmailModalStateEnum;
  isLoading: boolean;
  isPriceLoading: boolean;
  isFeedingPreferencesEnabled: boolean;
  feedingPreferences: any;
  showUpcomingNutritionalPlan: boolean;
  defaultSelectedNutritionalPlan: string;
  selectedFeedingPlanId: any;
  feedingPlanDates: Array<FeedingPlanHistoryDate>;
  historicalFeedingPlan: FeedingPlanData | null;
  selectedFeedingPlanDate?: Date;
  price: {
    breakdowns: [];
    total_before_discounts: null | number;
    total: null | number;
    total_discount: null | number;
    total_price_per_day: null | number;
  };
  products: Array<ProductDTO>;
  subscriptionProducts: Array<SubscriptionProductDTO>;
  areProductsLoading: boolean;
  productsSelectorError: string;
  priceBreakdownError: boolean;
  nutritionalPlanError: boolean;
  fortifloraProduct: ProductDTO | null;
  useRawProductDisplay: boolean;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  toCustomerDetails(): void;
  toDashboard(): void;
  toCustomerHub(): void;
  toPricingPage(): void;
  toConditionHistory(): void;
  addAnotherPet(): void;
  loadPetDetails(petId: number): void;
  pluralize(count: number, noun: string): string;
  resolveReproductiveStatus(gender: string, neutered: boolean): string;
  dietTypeFormatter(): string;
  editProfileModalTitle(petName: string): string;
  editProfileModalBody(petName: string): string;
  onEditProfileClicked(): void;
  onEditDietClicked(): void;
  onReactivationConfirmed(): Promise<void>;
  toOutOfClinicCheckout(): Promise<void>;
  isRegistrationError(): boolean;
  isRegistrationErrorMissingEmail(): boolean;
  onOutOfClinicCheckoutEmailSent(): void;
  mapHealthIssueQuestions(healthIssues: any): any;
  toggleLoading(flag: boolean): void;
  getFavouriteFlavoursForPresentation(preferences: Array<any>): string;
  onEditPetFlavourClicked(): void;
  loadFeedingPlanDates(petId: number): Promise<void>;
  loadHistoricalFeedingPlan(petId: number, feedingPlanId: number): Promise<void>;
  onFeedingPlanChange(value: any): void;
  onSubscriptionProductChange(value: any): void;
  loadPriceBreakdownForPet(petId: number): void;
  loadProductsData(): Promise<void>;
};

export default Vue.extend<Data, Methods, {}, any & Inject>({
  components: {
    FeedingPlan,
    ReactivateSubscription,
    HistoricalNutritionalPlan,
    WithOldVetBar,
    BrandModal,
    PortionAllocationComponent,
    LoadingSpinner,
    ActivateSubscription,
    PriceSummaryTable,
    SubscriptionProductsSelector,
    FortifloraProductSelector
  },
  name: "NutritionalPlan",
  inject: ["Api"],
  data() {
    return {
      submitDisabled: false,
      petId: +this.$route.params.pet_id,
      petDetails: new PetDetailsVM().initialValue(),
      feedingPlan: new FeedingPlanVM().initialValue(),
      subscriptionId: null,
      reproductiveStatus: "",
      isViewOrEditActiveMode:
        store.state.consultationMode === ConsultationModes.VIEW_OR_EDIT_ACTIVE,
      isCompleteSubscription: false,
      isCancelledSubscription: false,
      showEditProfileModal: false,
      showEditDietModal: false,
      customer: null,
      customerStatus: null,
      showNutritionalPlanErrorModal: false,
      registrationEmailModalStates: RegistrationEmailModalStateEnum,
      registrationEmailModalState: RegistrationEmailModalStateEnum.NONE,
      isLoading: true,
      isPriceLoading: true,
      isFeedingPreferencesEnabled: false,
      feedingPreferences: null,
      showUpcomingNutritionalPlan: true,
      defaultSelectedNutritionalPlan: "upcoming",
      selectedFeedingPlanId: "upcoming",
      feedingPlanDates: [] as Array<FeedingPlanHistoryDate>,
      historicalFeedingPlan: null,
      selectedFeedingPlanDate: new Date(),
      price: {
        breakdowns: [],
        total_before_discounts: null,
        total: null,
        total_discount: null,
        total_price_per_day: null
      },
      products: [] as Array<ProductDTO>,
      subscriptionProducts: [] as Array<SubscriptionProductDTO>,
      areProductsLoading: true,
      productsSelectorError: "",
      priceBreakdownError: false,
      nutritionalPlanError: false,
      fortifloraProduct: null,
      useRawProductDisplay: false
    };
  },
  async mounted() {
    if (!this.$store.getters.vetVuetifyViews) {
      this.isFeedingPreferencesEnabled = await FeatureFlags.isEnabled("v2p:feeding-preferences");
      this.toggleLoading(true);
      await this.loadPetDetails(this.petId);
      await this.loadPriceBreakdownForPet(this.petId);
      this.toggleLoading(false);
    }
  },
  computed: {
    mainTitle() {
      return this.showUpcomingNutritionalPlan
        ? `
      ${this.petDetails.name}'s nutritional plan`
        : `${
            this.petDetails.name
          }'s nutritional plan as of ${this.$options.filters?.nthDayDateDisplay(
            this.selectedFeedingPlanDate
          )}`;
    }
  },
  methods: {
    toggleLoading(flag: boolean): void {
      this.isLoading = flag;
    },
    async toCustomerDetails() {
      this.submitDisabled = true;
      await this.$router.push({
        name: this.routeNames.CUSTOMER_DETAILS,
        params: { customer_id: this.customer.customerId, pet_id: `${this.petId}` }
      });
    },
    async toPricingPage() {
      await this.$router.push({
        name: this.routeNames.PRICE,
        params: { customer_id: this.customer.customerId }
      });
    },
    async toDashboard() {
      this.submitDisabled = true;
      await this.$router.push({ name: this.routeNames.DASHBOARD });
    },
    async toCustomerHub() {
      this.submitDisabled = true;
      await this.$router.push({
        name: this.routeNames.CUSTOMER_HUB,
        params: { customer_id: this.customer.customerId }
      });
    },
    async toConditionHistory() {
      await this.$router.push({
        name: this.routeNames.CONDITION_HISTORY,
        params: { pet_id: `${this.petId}`, customer_id: this.customer.customerId }
      });
    },
    async addAnotherPet() {
      this.submitDisabled = true;
      await this.$router.push({
        name: this.routeNames.PET_INFORMATION,
        params: { customer_id: this.customer.customerId }
      });
      window.scrollTo(0, 0);
    },
    async loadPetDetails(petId: number) {
      const response: GetPetByIdResponse = await this.Api.getPetById(petId);

      if (response.success) {
        this.petDetails = mapPetDetailsResponseToViewModel(response.petDetails);
        this.$store.dispatch(ACTIONS.SET_PET_DETAILS, this.petDetails);
        this.reproductiveStatus = this.resolveReproductiveStatus(
          this.petDetails.gender,
          this.petDetails.neutered
        );
      } else {
        this.$store.dispatch(ACTIONS.SET_PET_DETAILS, null);
      }
      // these api calls can be started simultaneously
      const [
        ,
        ,
        // only comma, the first two values are not used
        nutritionalPlan,
        getCustomerDetailsResponse,
        feedingPreferencesResponse
      ] = await Promise.all([
        this.loadProductsData(),
        this.loadFeedingPlanDates(this.petId),
        this.Api.getNutritionalPlan(this.petId),
        this.Api.getCustomerDetails(this.petId),
        this.Api.getFeedingPreferences(this.petId)
      ]);

      if (nutritionalPlan.success) {
        this.feedingPlan = mapNutritionalPlanToViewModel(nutritionalPlan.resp, this.petDetails);
        this.$store.dispatch(ACTIONS.SET_FEEDING_PLAN, this.feedingPlan);
      } else {
        this.$store.dispatch(ACTIONS.SET_FEEDING_PLAN, null);
        // eslint-disable-next-line
        this.showNutritionalPlanErrorModal = true;
        this.nutritionalPlanError = true;
      }
      if (getCustomerDetailsResponse.success) {
        this.customer = mapCustomerDetailsPayloadToViewModel(getCustomerDetailsResponse.customer);
        this.customerStatus = getCustomerDetailsResponse.customer.status;
      }

      if (feedingPreferencesResponse.success) {
        this.feedingPreferences = this.getFavouriteFlavoursForPresentation(
          feedingPreferencesResponse.resp.flavours
        );
      }
      this.isCompleteSubscription =
        this.petDetails.subscriptionStatus === "complete" &&
        this.petDetails.petStatus === "complete" &&
        this.customerStatus === "complete";
      this.isCancelledSubscription = this.petDetails.subscriptionStatus === "cancelled";
    },
    async loadProductsData(): Promise<void> {
      try {
        this.products = await productService.getProducts();
        this.subscriptionProducts = await productService.getSubscriptionProducts(
          this.petDetails.subscriptionId
        );
      } catch {
        this.productsSelectorError =
          "Could not load products. If the error persists, please contact support.";
        // after handling an error, resolve to prevent Promise.all fail
        await Promise.resolve();
      } finally {
        this.areProductsLoading = false;
      }
      if (this.products.length > 0) {
        // it's ok if we have only one product
        this.fortifloraProduct = this.products[0];
        this.useRawProductDisplay = !this.fortifloraProduct.descriptionSections;
      }
    },
    mapHealthIssueQuestions() {
      const service = new TherapeuticSelectableHealthIssuesService();
      const petHealthIds = this.petDetails.healthIssue.map(issue => issue.healthIssueId);
      const issues = this.petDetails.healthIssue
        .map(issue => service.getHealthIssueFollowUpQuestion(issue.healthIssueId, petHealthIds))
        .filter(issue => issue !== undefined);

      return issues;
    },
    pluralize(count: number, noun: string): string {
      return `${count} ${noun}${count !== 1 ? "s" : ""}`;
    },
    resolveReproductiveStatus(gender: string, neutered: boolean): string {
      if (!neutered) {
        return "intact";
      }
      if (gender === "male") {
        return "neutered";
      }
      return "spayed";
    },
    dietTypeFormatter(): string {
      return this.feedingPlan.dietType.replace("vet_", "");
    },
    editProfileModalTitle: (petName: string): string =>
      `Do you want to update ${petName}'s profile?`,
    editProfileModalBody: (petName: string): string =>
      `Making a change to ${petName}'s profile will pause their subscription. To reactivate, the customer must consent to the change in their new plan and price.`,
    onEditProfileClicked(): void {
      if (this.isViewOrEditActiveMode) {
        this.showEditProfileModal = true;
      } else {
        this.$router.push({
          name: "pet-information",
          params: { pet_id: this.petId.toString(), customer_id: this.customer.customerId }
        });
      }
    },
    onEditPetFlavourClicked(): void {
      this.$router.push({
        name: this.routeNames.FEEDING_PREFERENCES,
        params: { pet_id: this.petId.toString() }
      });
    },
    onEditDietClicked(): void {
      if (this.isViewOrEditActiveMode) {
        this.showEditDietModal = true;
      } else {
        this.$router.push({
          name: "diet-requirements",
          params: { pet_id: this.petId.toString() }
        });
      }
    },

    getFavouriteFlavoursForPresentation(preferences: Array<any>) {
      if (preferences.length > 0) {
        return preferences.map((record: any) => capitalize(record.name)).join(", ");
      }
      return "Not Fussy";
    },

    async onReactivationConfirmed(): Promise<void> {
      this.submitDisabled = true;
      const activationResult = await this.Api.activateSubscription(this.petId);
      if (activationResult.success) {
        await this.$router.push({ name: "thank-you" });
      } else {
        // Display error
        this.submitDisabled = false;
      }
    },
    async toOutOfClinicCheckout(): Promise<void> {
      this.submitDisabled = true;
      this.registrationEmailModalState = this.registrationEmailModalStates.NONE;
      const response = await this.Api.initOutOfClinicCheckout(this.petId);
      if (response.success) {
        this.registrationEmailModalState = this.registrationEmailModalStates.SUCCESS;
      } else {
        this.registrationEmailModalState = this.registrationEmailModalStates.ERROR;
      }
      this.submitDisabled = false;
    },
    isRegistrationError(): boolean {
      return (
        this.registrationEmailModalState === this.registrationEmailModalStates.ERROR ||
        this.isRegistrationErrorMissingEmail()
      );
    },
    isRegistrationErrorMissingEmail(): boolean {
      return !(this.customer && this.customer.email);
    },
    onOutOfClinicCheckoutEmailSent() {
      if (!this.isRegistrationError()) {
        this.toDashboard();
      } else {
        this.registrationEmailModalState = this.registrationEmailModalStates.NONE;
      }
    },
    async loadFeedingPlanDates(petId: number): Promise<void> {
      const feedingPlanResponse = await feedingPlanService.getPetFeedingPlanDates(petId);
      if (feedingPlanResponse.success) {
        this.feedingPlanDates = feedingPlanResponse.feedingPlanDates;
      }
    },
    async loadHistoricalFeedingPlan(petId: number, feedingPlanId: number) {
      this.toggleLoading(true);
      const feedingPlanResponse = await feedingPlanService.getFeedingPlanData(petId, feedingPlanId);
      if (feedingPlanResponse.success) {
        this.historicalFeedingPlan = feedingPlanResponse;
        this.selectedFeedingPlanDate = feedingPlanResponse.date;
      } else {
        this.showNutritionalPlanErrorModal = true;
      }
      this.toggleLoading(false);
    },
    async onFeedingPlanChange(value: any): Promise<void> {
      if (value === this.defaultSelectedNutritionalPlan) {
        this.showUpcomingNutritionalPlan = true;
        await this.loadPetDetails(this.petId);
      } else {
        this.showUpcomingNutritionalPlan = false;
        await this.loadHistoricalFeedingPlan(this.petId, +value);
      }
    },
    async onSubscriptionProductChange(value: { active?: boolean; success: boolean }) {
      if (value.success) {
        await this.loadPriceBreakdownForPet(this.petId);
      }
    },
    async loadPriceBreakdownForPet(petId: number) {
      this.isPriceLoading = true;
      try {
        const response = await priceBreakdownService.getTotalPriceBreakdownForPet(petId);
        this.price.breakdowns = response.subscriptions.map(
          (sub: any) => new PriceBreakdown({}, sub)
        );
        this.price.total_before_discounts = response.total_before_discounts;
        this.price.total_discount = response.total_discount;
        this.price.total_price_per_day = response.total_price_per_day;
        this.price.total = response.total;
      } catch (e) {
        this.priceBreakdownError = true;
      }
      this.isPriceLoading = false;
    }
  }
});
