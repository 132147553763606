
import Vue from "vue";
import { DietType } from "@/api/api.dto";

export default Vue.extend<any, any, any, any>({
  name: "TherapeuticDiet",
  inject: ["Api"],
  props: {
    pselected: Object,
    petId: Number
  },
  data() {
    return {
      selected: {
        primary: this.pselected.primary,
        secondary: this.pselected.secondary
      },
      options: {
        firstLevel: [],
        secondLevel: []
      }
    };
  },
  async created() {
    const primaryIssues = await this.Api.getSelectableHealthIssues({
      pet_id: this.petId,
      diet_type: DietType.Therapeutic
    });
    this.options.firstLevel = primaryIssues.resp.health_issues;

    if (this.pselected.primary) {
      const secondIssues = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: DietType.Therapeutic,
        primary_health_issue_id: this.pselected.primary
      });
      this.options.secondLevel = secondIssues.resp.health_issues;
      this.$emit("validateSelection", true);
    }
    this.$emit("loaded");
  },
  methods: {
    onHealthIssuesSelected() {
      this.$emit("onHealthIssuesSelected", this.selected);
    },
    async onFirstOptionSelected(event: any) {
      this.$emit("clearInvalidSelectionErrorMessage");
      // Reset second options and selection
      this.options.secondLevel = [];

      this.$delete(this.selected, "secondary");

      const result = await this.Api.getSelectableHealthIssues({
        pet_id: this.petId,
        diet_type: DietType.Therapeutic,
        primary_health_issue_id: event.target.value
      });
      this.options.secondLevel = result.resp.health_issues;
      this.onHealthIssuesSelected();
      this.$emit("validateSelection", true);
    },
    onSecondOptionSelected(): void {
      this.onHealthIssuesSelected();
      this.$emit("clearInvalidSelectionErrorMessage");
    },
    showSecondLevel(): boolean {
      return !!this.options.secondLevel && this.options.secondLevel.length > 0;
    }
  }
});
