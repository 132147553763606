
import Vue from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { ApiInterface } from "@/api/api.interface";
import { EnvironmentVariables } from "@/services/environmentVariables.service";
import customerService from "@/services/customer/customer.service";

type Data = {
  submitDisabled: boolean;
  errorMessage: string;
  stripeCard: any;
  stripeClient: any;
  stripeElements: any;
  stripePublicKey: string;
  customerHasPaymentMethod: boolean;
  isUpdating: boolean;
  last4digits?: string;
  showSubscriptionActivationError?: boolean;
};

type Inject = {
  Api: ApiInterface;
};

export default Vue.extend<Data, any, any, any & Inject>({
  name: "Payment",
  inject: ["Api"],
  props: ["customerId", "clientSecret", "doDisplayErrorRetry", "petId", "shouldDisplaySpinner"],
  data() {
    return {
      submitDisabled: false,
      errorMessage: "",
      stripeCard: null,
      stripeClient: null,
      stripeElements: null,
      stripePublicKey: EnvironmentVariables.stripePublicKey(),
      customerHasPaymentMethod: false,
      last4digits: "",
      isUpdating: false,
      showSubscriptionActivationError: false
    };
  },
  methods: {
    onChange(event: any): void {
      // Handle real-time validation errors from the card Element.
      // Set errorMessage to whichever message Stripe returns as it's
      // expected to be useful for the user.
      if (event.error) {
        this.errorMessage = event.error.message;
      } else {
        this.errorMessage = "";
      }
    },
    async onSubmit(event: Event): Promise<void> {
      this.shouldDisplaySpinner(true);
      event.preventDefault();
      this.submitDisabled = true;
      const result = await this.stripeClient.confirmCardSetup(this.clientSecret, {
        payment_method: { card: this.stripeCard }
      });
      if (result.setupIntent) {
        try {
          const response = await this.Api.submitPaymentToken(
            result.setupIntent.payment_method,
            this.customerId,
            this.petId
          );
          if (!response.success) {
            this.doDisplayErrorRetry();
            this.submitDisabled = false;
          } else {
            this.navigateToNextPage();
          }
        } catch (error) {
          this.doDisplayErrorRetry();
          this.submitDisabled = false;
        }
      } else if (!(result.error && result.error.type === "validation_error")) {
        this.doDisplayErrorRetry();
        this.submitDisabled = false;
      } else {
        this.submitDisabled = false;
      }
      this.shouldDisplaySpinner(false);
    },
    async onUpdateCardClick(event: Event) {
      event.preventDefault();
      this.customerHasPaymentMethod = false;
      this.isUpdating = true;
    },
    async onContinueClick(event: Event) {
      event.preventDefault();
      this.submitDisabled = true;
      this.showSubscriptionActivationError = false;
      const activationResponse = await this.Api.activateSubscription(this.petId);
      if (activationResponse.success) {
        this.navigateToNextPage();
      } else {
        this.submitDisabled = false;
        this.showSubscriptionActivationError = true;
      }
    },
    async navigateToNextPage() {
      await this.$router.push({ name: "thank-you" });
    }
  },
  async mounted() {
    this.stripeClient = await loadStripe(this.stripePublicKey);
    this.stripeElements = await this.stripeClient.elements();
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    // Create an instance of the card Element.
    this.stripeCard = this.stripeElements.create("card", {
      hidePostalCode: true,
      style
    });

    // Add an instance of the card Element into the `card-element` <div>.
    this.stripeCard.mount("#card-element");

    try {
      const paymentMethod = await customerService.getCurrentPaymentDetails(this.customerId);
      this.customerHasPaymentMethod = paymentMethod.paymentMethodExists;
      this.last4digits = paymentMethod.lastFourDigits;
    } catch (e) {
      this.customerHasPaymentMethod = false;
    }
  }
});
