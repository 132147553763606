import Pagination from "@/api/pagination.dto";

export type GenericPayloadAttributeErrors = {
  detail: string;
  source?: string;
  title?: string;
};

export type UpdatePetRequest = {
  activity_level: string;
  breed: number;
  condition: string;
  date_of_birth: string;
  gender: string;
  is_neutered: boolean;
  name: string;
  weight: number;
  customer_id: number;
  vet_practice_id: number;
  pet_type_id: number;
};

export type PartialUpdatePetRequest = {
  name: string;
  activity_level?: string;
  breed?: number;
  condition?: string;
  date_of_birth?: string;
  gender?: string;
  is_neutered?: boolean;
  weight?: number;
  customer_id?: number;
  practice_id?: number;
  pet_type_id?: number;
  health_issues?: {
    diet_type: DietType;
    primary_health_issue_id: number;
    secondary_health_issue_id?: number;
    tertiary_health_issue_id?: number;
  };
};

export type CreateCustomerRequest = {
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  data_capture_consent: boolean;
  pet_health_club_member: boolean;
};

export type UpdatePetResponse = {
  success: boolean;
  errorMessage?: string;
  pet_id: number;
  errors?: any;
};

export type CreateCustomerResponse = {
  success: boolean;
  errors?: Array<any>;
  customer_id: number;
};

export type BreedRequestPayload = {
  breed_id: number;
  name: string;
};

export type GetAllBreedsResponse = {
  success: boolean;
  breeds: BreedRequestPayload[];
};

export type HealthIssueResponsePayload = {
  health_issue_id: number;
  name_default: string;
};

export type GetAllHealthConditionsResponse = {
  success: boolean;
  healthIssues: HealthIssueResponsePayload[];
  errorMessage?: string;
};

export type PetBodyConditionRequestPayload = {
  identifier: string;
  name: string;
  condition_id: number;
  description: string;
  message: string;
};

export type GetAllPetBodyConditionsResponse = {
  success: boolean;
  conditions: PetBodyConditionRequestPayload[];
  errorMessage?: string;
};

export enum DietType {
  Maintenance = "maintenance",
  Therapeutic = "therapeutic"
}

export type SaveDietRequirementsRequest = {
  pet_id: number;
  diet_type: DietType;
  primary_health_issue_id: number;
  secondary_health_issue_id?: number;
  tertiary_health_issue_id?: number;
};

export type SaveDietRequirementsResponse = {
  success: boolean;
  errorMessage?: string;
};

export type PetAge = {
  years: number;
  months: number;
  days: number;
};

export type PetDetailsResponsePayload = {
  activity_level: {
    identifier: string;
    description_default: string;
    name_default: string;
  };
  age: PetAge;
  allergens?: {
    allergen_id: number;
    name_default: string;
  }[];
  breed: {
    breed_id: number;
    name: string;
  };
  condition: {
    condition_id: number;
    identifier: string;
    name_default: string;
  };
  date_of_birth: string;
  diet_type?: string;
  gender: string;
  health_condition: {
    display_name_default: string;
    health_issue_id: number;
    name_default: string;
  }[];
  is_neutered: boolean;
  name: string;
  pet_id: number;
  status: string;
  weight: number;
  subscription_status: string;
  subscription_id: number;
  customer_id: number;
  size: string;
  current_life_stage: string;
  next_life_stage: string;
  next_life_stage_transition_date: string;
  vet_practice_id: number;
  pet_type_id: number;
};

export type GetPetByIdResponse = {
  success: boolean;
  errorMessage?: string;
  petDetails: PetDetailsResponsePayload;
};

export type Address = {
  first_line: string;
  second_line?: string;
  third_line?: string;
  city: string;
  county?: string;
  postal_code: string;
};

export type DeliveryAddress = {
  company?: string;
  delivery_instruction_other?: string;
} & Address;

export type CustomerDetailsRequestPayload = {
  billing_same_as_shipping: boolean;
  billing_address?: Address;
  contact_consent: boolean;
  delivery_address: DeliveryAddress;
  email: string;
  first: string;
  last: string;
  mobile_phone: string;
  password: string;
};

export type CustomerDetailsResponse = {
  customer_id: number;
  email: string;
  first: string;
  last: string;
  mobile_phone: string;
  status: string;
  billing_same_as_shipping: boolean;
  billing_address?: Address;
  contact_consent: boolean;
  delivery_address: DeliveryAddress;
  data_capture_consent: boolean;
  pet_health_club_member: boolean;
};

export type CustomerDetailsResponsePayload = {
  success: boolean;
  errorMessage?: string;
  errors?: GenericPayloadAttributeErrors[];
  customer?: CustomerDetailsResponse;
};

export type VetLoginResponse = {
  success: boolean;
  errorMessage?: string;
  apitoken: string;
  vet_name: string;
  vet_practice_group_name: string;
  authenticated: boolean;
};

export type VetLogoutResponse = {
  success: boolean;
  errorMessage?: string;
};

export type SubmitPaymentTokenResponsePayload = {
  success: boolean;
  errorMessage?: string;
};

export type PaymentClientSecretResponse = {
  success: boolean;
  errorMessage?: string;
  clientSecret: string;
};

export enum SubscriptionStatus {
  PENDING = "pending",
  COMPLETE = "complete",
  ACTIVE = "active",
  CANCELLED = "cancelled",
  PAUSED = "paused"
}

export type SearchPetsPayload = {
  customer_email: string;
  customer_name: string;
  last_modified: number;
  name: string;
  pet_id: number;
  // Replace type by SubscriptionStatus
  status: string;
};

export type SearchPetsResponse = {
  success: boolean;
  errorMessage?: string;
  resp: SearchPetsPayload[];
};

export type GetNutritionalPlanResponsePayload = {
  blend_data: {
    additives: {
      nutritional: {
        iu_kg: string;
        mg_kg: string;
      };
      technological?: {
        mg_kg: string;
      };
    };
    analytical_constituents: string;
    composition: string;
    claims: {
      title?: string;
      description: string;
    }[];
  };
  blend_product: {
    weight_per_days_grams: number;
    weight_per_order_grams: number;
  };
  diet_type: string;
  feeding_guide: {
    feeds_per_day: number;
    scoop_position: string;
    scoops_per_feed: number;
  };
  order_days: number;
  price: number;
  price_breakdown: {
    total_price_vat_included: number;
    total_price_before_discount_vat_included: number;
    promo_rewards: Array<string>;
    price_per_day: number;
  };
  target_daily_dry_kcal: number;
  target_kcal_percent: number;
  original_target_kcal: number;
  portion_multiplier: number;
  total_daily_kcal_requirement: number;
  kcal_range: {
    min: number;
    max: number;
  };
};

export type GetNutritionalPlanResponse = {
  success: boolean;
  errorMessage?: string;
  resp: GetNutritionalPlanResponsePayload;
};

export type GetSelectableHealthIssuesQueryParameters = {
  pet_id: number;
  diet_type: DietType;
  primary?: number;
  secondary?: number;
};

export type GetSelectableHealthIssuesResponsePayload = {
  pet_id: number;
  health_issues: {
    health_issue_id: number;
    health_issue_name: string;
  }[];
};

export type GetSelectableHealthIssuesResponse = {
  success: boolean;
  errorMessage?: string;
  resp: GetSelectableHealthIssuesResponsePayload;
};

export type SelectableUrgentHealthIssue = {
  id: number;
  display_name: string;
  dispensing_guidance?: string;
};

export type UrgentDiet = {
  urgent_health_issue: SelectableUrgentHealthIssue;
  end_date: string;
};

export type UrgentDietGetPayload = {
  active_urgent_diet?: UrgentDiet;
  has_active_urgent_diet: boolean;
  selectable_urgent_health_issues?: SelectableUrgentHealthIssue[];
};

export type UrgentDietResponse = {
  success: boolean;
  errors?: GenericPayloadAttributeErrors;
  resp?: UrgentDietGetPayload;
};

export type UrgentDietPostPayload = {
  urgent_health_issue_id: number;
  number_of_days: number;
};

export type UrgentDietPostResponsePayload = {
  success: boolean;
  errorMessage?: string;
  resp?: SelectableUrgentHealthIssue;
  errors?: GenericPayloadAttributeErrors[];
};

export type CustomerCurrentPaymentMethodResponse = {
  success: boolean;
  errors?: GenericPayloadAttributeErrors[];
  resp: {
    payment_method_exists: boolean;
    last_four_digits?: string;
  };
};

export type ActivateSubscriptionResponse = {
  success: boolean;
  errorMessage?: string;
};

export type SetNewPasswordResponse = {
  success: boolean;
  errorMessage?: string;
  expiredToken?: boolean;
};

export type RequestPasswordResetResponse = {
  success: boolean;
  errorMessage?: string;
};

export type InitOutOfClinicCheckoutResponse = {
  success: boolean;
  errorMessage?: string;
};

export type Tag = {
  tag_id: number;
  identifier: string;
};

export type CustomerType = {
  customer_type_id: number;
  name: string;
  description: string;
  tag: Tag;
};

export type TotalPriceBreakdownResponse = {
  subscriptions: any;
  total: number;
  total_before_discounts: number;
  total_price_per_day: number;
  total_discount: number;
};

export interface ApiNoDataResponse {
  success: boolean;
  errorMessage?: string;
}

export interface ApiResponse<T> extends ApiNoDataResponse {
  resp: T;
}

export type Flavour = {
  identifier: string;
  name: string;
};

export type FeedingPreferencePayload = {
  flavours: Flavour[];
  brands: ThirdPartyBrand[];
};

export type ThirdPartyBrand = {
  id: string;
  name: string;
  type: string;
};

export type ThirdPartyBrandPayload = {
  brands: ThirdPartyBrand[];
};

export type HistoricalConditionRecord = {
  condition_record_id: number;
  condition_id: number;
  created: Date;
  name: string;
  pet_age_at_record_creation: PetAge;
  weight: number;
};
export type ConditionRecordsHistoryResponse = {
  condition_records?: Array<HistoricalConditionRecord>;
  pagination?: Pagination;
  success: boolean;
};
