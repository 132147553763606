
import Vue, { PropType } from "vue";
import type { FeedingGuideDTO, BlendProductDTO } from "@/api/v2/feeding-plan";

export default Vue.extend({
  name: "ScoopSettings",
  props: {
    feedingGuide: { type: Object as PropType<FeedingGuideDTO> },
    blendProduct: { type: Object as PropType<BlendProductDTO> },
    petName: String
  }
});
