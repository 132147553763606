<template>
  <div v-if="show" id="main-card" class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          When the weight is updated, previous manual changes to the DER and the amount of other
          food may be lost.
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <span>Weight (kg)</span>
          <input v-model="weight" type="text" class="form-control" style="max-width: 100px" />
        </div>
        <div class="col-3">
          <span>Body condition score (1-9)</span>
          <input v-model="bcs" type="text" class="form-control" style="max-width: 50px" />
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col">
              <button
                @click="show = false"
                class="v2p-confirm-button component-button mr-2"
                style="border: solid 1px black; border-radius: 5px;"
              >
                cancel
              </button>
              <button
                class="v2p-confirm-button-orange component-button"
                style="border-radius: 5px;"
                @click="updateConditionRecord"
              >
                update
              </button>
            </div>
          </div>
          <div class="row" v-if="error" style="color: red">
            <div class="col">{{ error }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import petService from "@/services/pet.service";
import { RouteNames } from "@/router/routeNames";

export default {
  name: "UpdateWeight",
  props: ["value", "petId", "petName", "petWeight", "petBcs", "customerId"],
  data() {
    return {
      show: this.value,
      weight: this.petWeight,
      bcs: this.petBcs,
      error: ""
    };
  },
  watch: {
    show() {
      this.$emit("input", this.show);
    }
  },
  methods: {
    async updateConditionRecord() {
      this.weight = +this.weight;
      if (this.weight < 0.5) {
        this.error = "Weight cannot be lower than 0.5kg";
        return;
      }
      this.bcs = Math.floor(+this.bcs);
      if (this.bcs < 1 || this.bcs > 9) {
        this.error = "BCS has to be in range from 1 to 9 (inclusive)";
        return;
      }
      this.error = "";
      await petService.updateConditionRecord(this.petId, this.weight, this.bcs);
      const message = `${this.petName}'s weight and nutritional plan has been updated.`;
      await this.$router.push({
        name: RouteNames.NUTRITIONAL_PLAN,
        params: {
          pet_id: this.petId,
          customer_id: this.customerId,
          alert: { message: message, type: "success" }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#main-card {
  font-size: 12px;

  .component-button {
    font-weight: 600 !important;
    text-transform: capitalize !important;
  }
}
</style>
