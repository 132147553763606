
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {
  ApiResponse,
  FeedingPreferencePayload,
  Flavour,
  GetPetByIdResponse,
  ThirdPartyBrand
} from "@/api/api.dto";
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";
import { FeatureFlags } from "@/api/featureFlags/featureFlags.api";
import WithAppBar from "@/layouts/WithAppBar.vue";

type Data = {
  petId: number;
  petName: string;
  customerId?: number;
  isLoading: boolean;
  selectedFlavours: Array<string>;
  selectedBrands: Array<string>;
  search: string;
  flavoursOptions: Array<{}>;
  thirdPartyBrands: Array<any>;
  feedingPreferences: FeedingPreferencePayload;
  errorMessage?: string;
  brandErrorMessage?: string;
  isFeedingPreferencesEnabled: boolean;
  isBrandQuestionEnabled: boolean;
  cantFindThirdPartyBrand: boolean;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  saveAndGoToNextPage(): Promise<void>;
  getPetData(): Promise<void>;
  getFlavours(): Promise<void>;
  getFeedingPreferences(): Promise<void>;
  getFlags(): Promise<void>;
  getThirdPartyBrands(): Promise<void>;
};

type Computed = {
  notFussySelect(): boolean;
  canSaveFlavours(): boolean;
};

export default Vue.extend<any, Methods, {}, any & Inject>({
  components: {
    WithAppBar,
    LoadingSpinner
  },
  name: "FeedingPreferences",
  inject: ["Api"],
  data() {
    return {
      submitDisabled: false,
      petId: +this.$route.params.pet_id,
      petName: "",
      customerId: undefined,
      isLoading: true,
      selectedFlavours: [],
      selectedBrands: [],
      search: null,
      flavoursOptions: [],
      thirdPartyBrands: [],
      feedingPreferences: {} as FeedingPreferencePayload,
      errorMessage: undefined,
      brandErrorMessage: undefined,
      isFeedingPreferencesEnabled: false,
      isBrandQuestionEnabled: false,
      cantFindThirdPartyBrand: false
    };
  },
  computed: {
    notFussySelect() {
      return this.selectedFlavours.length === 0;
    },
    canSaveFlavours() {
      return (
        !this.isLoading && this.errorMessage === undefined && this.brandErrorMessage === undefined
      );
    }
  },
  mounted() {
    Promise.all([
      this.getPetData(),
      this.getFlavours(),
      this.getFeedingPreferences(),
      this.getThirdPartyBrands(),
      this.getFlags()
    ])
      .catch(error => {
        this.errorMessage = error.message;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  watch: {
    cantFindThirdPartyBrand(val) {
      if (val) this.selectedBrands = [];
    }
  },
  methods: {
    async saveAndGoToNextPage(): Promise<void> {
      let feedingPreferencesResp = null;
      this.submitDisabled = true;
      if (this.isFeedingPreferencesEnabled) {
        feedingPreferencesResp = await this.Api.assignFeedingPreferences(
          this.petId,
          this.selectedFlavours,
          this.selectedBrands
        );
        if (!feedingPreferencesResp.success) {
          this.errorMessage = feedingPreferencesResp.errorMessage;
          this.submitDisabled = false;
        }
      }
      if (feedingPreferencesResp && feedingPreferencesResp.success) {
        await this.$router.push({
          name: "diet-requirements",
          params: { pet_id: this.petId.toString() }
        });
      }
      this.submitDisabled = false;
    },

    async getFlags(): Promise<void> {
      this.isFeedingPreferencesEnabled = await FeatureFlags.isEnabled("v2p:feeding-preferences");
      this.isBrandQuestionEnabled = await FeatureFlags.isEnabled("v2p:brand-question");
    },

    async getPetData(): Promise<void> {
      const response: GetPetByIdResponse = await this.Api.getPetById(this.petId);
      if (response.success) {
        this.petName = response.petDetails.name;
        this.customerId = response.petDetails.customer_id;
      } else {
        throw new Error(response.errorMessage);
      }
    },

    async getFlavours(): Promise<void> {
      const response: ApiResponse<Flavour[]> = await this.Api.getFlavours();
      if (response.success) {
        this.flavoursOptions = response.resp.map((f: Flavour) => ({
          text: f.name,
          value: f.identifier
        }));
      } else {
        throw new Error(response.errorMessage);
      }
    },

    async getThirdPartyBrands(): Promise<void> {
      const response: ApiResponse<any> = await this.Api.getThirdPartyBrands();
      if (response.success) {
        this.thirdPartyBrands = response.resp.map((b: ThirdPartyBrand) => ({
          text: b.name,
          value: b.id
        }));
      }
    },

    async getFeedingPreferences(): Promise<void> {
      const response: ApiResponse<FeedingPreferencePayload> = await this.Api.getFeedingPreferences(
        this.petId
      );
      if (response.success) {
        this.feedingPreferences = response.resp;
        this.selectedFlavours = this.feedingPreferences.flavours.map((f: Flavour) => f.identifier);
        this.selectedBrands = this.feedingPreferences.brands.map((f: ThirdPartyBrand) => f.id);
      } else {
        throw new Error(response.errorMessage);
      }
    }
  }
});
