export default class Pagination {
  page?: number;
  totalResults?: number;
  totalPages?: number;

  public constructor(init?: Partial<Pagination>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        page: rawData.page,
        totalResults: rawData.total_results,
        totalPages: rawData.total_pages
      });
    }
  }
}
