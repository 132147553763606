
import Vue, { PropType } from "vue";

export interface NutritionalInfoProps {
  analyticalConstituents: string;
  additives: {
    nutritional: { iu_kg: string; mg_kg: string };
    technological: { mg_kg: string };
  };
}

export default Vue.extend({
  name: "NutritionalInfo",
  props: {
    nutritionalInfo: {
      type: Object as PropType<NutritionalInfoProps>
    }
  },
  computed: {
    analyticalConstituents(): string[] {
      return this.nutritionalInfo.analyticalConstituents.split("\n");
    },
    nutritionalAdditivesIU(): string[] {
      return this.nutritionalInfo.additives.nutritional.iu_kg.split("\n");
    },
    nutritionalAdditivesMG(): string[] {
      return this.nutritionalInfo.additives.nutritional.mg_kg.split("\n");
    },
    technologicalAdditives(): string[] {
      return this.nutritionalInfo.additives.technological.mg_kg.split("\n");
    }
  }
});
