
import { DeliveryWindow } from "@/services/courier.service";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "DeliveryDates",
  props: {
    deliveryWindow: { type: Object as PropType<DeliveryWindow> }
  },
  computed: {
    estimatedDate(): string {
      const maxD = this.deliveryWindow.max;
      const minD = this.deliveryWindow.min;

      if (maxD.month() === minD.month()) {
        if (maxD.day() === minD.day()) {
          return minD.format("DD MMM YYYY");
        }
        return `${minD.format("DD")} - ${maxD.format("DD MMM YYYY")}`;
      }
      return `${minD.format("DD MMM")} - ${maxD.format("DD MMM YYYY")}`;
    },
    estimatedDateWithoutYear(): string {
      return this.estimatedDate.slice(0, -5); // remove whitespace and year
    },
    orderPlaced(): string {
      return this.deliveryWindow.fulfilment.format("DD MMM");
    },
    orderDispatches(): string {
      const dispatch_date = this.deliveryWindow.dispatch_date;
      const minD = this.deliveryWindow.min;

      if (dispatch_date.month() === minD.month()) {
        if (dispatch_date <= minD) {
          return dispatch_date.format("DD MMM");
        }
        return `${minD.format("DD")} - ${dispatch_date.format("DD MMM")}`;
      }
      return `${minD.format("DD MMM")} - ${dispatch_date.format("DD MMM")}`;
    }
  }
});
