import { GetNutritionalPlanResponsePayload } from "@/api/api.dto";
import { HealthIssues, PetDetails } from "@/components/common.vm";
import ConditionRecord from "@/models/condition-record.model";

type Benefits = {
  primaryBenefit?: string;
  secondaryBenefit?: string;
  tertiaryBenefit?: string;
};

export type Claim = {
  description: string;
  title?: string;
};

export type RecommendedDiet = {
  benefits: Benefits;
  blendData: {
    additives: {
      nutritional: {
        iu_kg: string;
        mg_kg: string;
      };
      technological?: {
        mg_kg: string;
      };
    };
    analytical_constituents: string;
    claims: Claim[];
    composition: string;
  };
  dietType: string;
  feedingGuide: {
    feeds_per_day: number;
    scoop_position: string;
    scoops_per_feed: number;
  };
  orderDays: number;
  price: number;
  weightPerDayGrams: number;
  weightPerOrderGrams: number;
  totalPriceVATIncluded: number;
  totalPriceBeforeDiscountVATIncluded: number;
  pricePerDay: number;
  promoRewards: Array<string>;
  portionMultiplier: number;
  originalTargetKcal: number;
  totalDailyKcalRequirement: number;
  kcalRange: {
    min: number;
    max: number;
  };
};

function mapMaintenanceBenefits(claims: Claim[]): Benefits {
  const benefits = {} as Benefits;
  if (claims.length >= 3) {
    benefits.tertiaryBenefit = claims[2].title || "";
  }
  if (claims.length >= 2) {
    benefits.secondaryBenefit = claims[1].title || "";
  }
  if (claims.length >= 1) {
    benefits.primaryBenefit = claims[0].title || "";
  }
  return benefits;
}

function mapTherapeuticBenefits(healthIssues: Array<{ displayName: string }>): Benefits {
  const benefits = {} as Benefits;
  if (healthIssues.length >= 2) {
    benefits.primaryBenefit = healthIssues[1].displayName;
  }
  if (healthIssues.length >= 1) {
    benefits.secondaryBenefit = healthIssues[0].displayName;
  }
  return benefits;
}

function setPromoRewards(promo_rewards: any): Array<string> {
  return (promo_rewards || [])
    .filter((item: any) => item.description)
    .map((item: any) => item.description);
}

export function mapNutritionalPlanToViewModel(
  nutritionalPlan: GetNutritionalPlanResponsePayload,
  petDetails: PetDetails
): RecommendedDiet {
  const diet = {
    benefits: {},
    blendData: nutritionalPlan.blend_data,
    dietType: nutritionalPlan.diet_type,
    feedingGuide: nutritionalPlan.feeding_guide,
    orderDays: nutritionalPlan.order_days,
    price: nutritionalPlan.price,
    weightPerDayGrams: nutritionalPlan.blend_product
      ? Math.round(nutritionalPlan.blend_product.weight_per_days_grams * 100) / 100
      : 0,
    weightPerOrderGrams: nutritionalPlan.blend_product
      ? nutritionalPlan.blend_product.weight_per_order_grams
      : 0,
    totalPriceVATIncluded: nutritionalPlan.price_breakdown.total_price_vat_included,
    totalPriceBeforeDiscountVATIncluded:
      nutritionalPlan.price_breakdown.total_price_before_discount_vat_included,
    pricePerDay: nutritionalPlan.price_breakdown.price_per_day,
    promoRewards: setPromoRewards(nutritionalPlan.price_breakdown.promo_rewards),
    targetDailyDryKcal: nutritionalPlan.target_daily_dry_kcal,
    targetKcalPercent: nutritionalPlan.target_kcal_percent,
    portionMultiplier: nutritionalPlan.portion_multiplier,
    originalTargetKcal: nutritionalPlan.original_target_kcal,
    totalDailyKcalRequirement: nutritionalPlan.total_daily_kcal_requirement,
    kcalRange: {
      min: nutritionalPlan.kcal_range?.min,
      max: nutritionalPlan.kcal_range?.max
    }
  } as RecommendedDiet;
  if (diet.blendData && diet.blendData.claims) {
    if (diet.dietType === "vet_maintenance") {
      diet.benefits = mapMaintenanceBenefits(diet.blendData.claims);
    } else {
      diet.benefits = mapTherapeuticBenefits(petDetails.healthIssue);
    }
  }
  return diet;
}

export class FeedingPlanVM {
  initialValue(): RecommendedDiet {
    return {
      benefits: {},
      blendData: {
        additives: {
          nutritional: {
            iu_kg: "",
            mg_kg: ""
          }
        },
        analytical_constituents: "",
        claims: [],
        composition: ""
      },
      dietType: "",
      feedingGuide: {
        feeds_per_day: 0,
        scoop_position: "",
        scoops_per_feed: 0
      },
      orderDays: 0,
      price: 0,
      weightPerDayGrams: 0,
      weightPerOrderGrams: 0,
      totalPriceVATIncluded: 0,
      totalPriceBeforeDiscountVATIncluded: 0,
      pricePerDay: 0,
      promoRewards: [],
      portionMultiplier: 1.0,
      originalTargetKcal: 0,
      totalDailyKcalRequirement: 0,
      kcalRange: {
        min: 100,
        max: 8000
      }
    } as RecommendedDiet;
  }
}

export class PetDetailsVM {
  initialValue(): PetDetails {
    return {
      activityLevel: {},
      breed: {},
      condition: new ConditionRecord(),
      healthIssue: [] as HealthIssues,
      name: ""
    } as PetDetails;
  }
}
