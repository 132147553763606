import axios from "axios";
import { PetAge } from "@/api/api.dto";

export type FeedingPlanHistoryDate = {
  feedingPlanId?: number;
  date?: Date;
};

export type FeedingPlanHistoryResponse = {
  feedingPlanDates: Array<FeedingPlanHistoryDate>;
  success: boolean;
};

export type FeedingPlanBlend = {
  analyticalConstituents: string;
  composition: string;
  additivesNutritional: Record<string, string>;
  additivesTechnological: Record<string, string>;
};

export type FeedingPlanPet = {
  petId: number;
  name: string;
  dateOfBirth: string;
  age: PetAge;
  weight: number;
  activityName: string;
  activityLevelDescription: string;
  bodyConditionId: number;
  bodyConditionName: string;
  bodyConditionDescription: string;
};

export type FeedingPlanDetails = {
  targetDailyKcal: number;
  otherKcalPercent: number;
  scoopPosition: string;
  scoopsPerFeed: number;
  feedPerDay: number;
  weightPerDayGrams: number;
  weightPerOrderGrams: number;
  orderDays: number;
};

export type FeedingPlanData = {
  feedingPlanId?: number;
  date?: Date;
  blend?: FeedingPlanBlend;
  pet?: FeedingPlanPet;
  feedingPlanDetails?: FeedingPlanDetails;
  success: boolean;
};

export class FeedingPlanService {
  private readonly baseUrl = "/api/vets/v1/pet/";

  public getPetFeedingPlanDates(petId: number): Promise<FeedingPlanHistoryResponse> {
    return axios
      .get(`${this.baseUrl}${petId}/feeding-plan-history`)
      .then(response => {
        const feedingPlanDates = response.data.resp.feeding_plan_dates;
        return {
          feedingPlanDates: feedingPlanDates.map((item: any) => {
            return { feedingPlanId: item.feeding_plan_id, date: new Date(item.date) };
          }),
          success: true
        };
      })
      .catch(() => {
        return { feedingPlanDates: [], success: false };
      });
  }

  private static buildFeedingPlanDataFromJson(json: any): FeedingPlanData {
    const blend = json.blend;
    const pet = json.pet;
    const feedingPlanDetails = json.feeding_plan_details;
    return {
      feedingPlanId: +json.feeding_plan_id,
      date: new Date(json.date),
      blend: {
        analyticalConstituents: blend.analytical_constituents,
        composition: blend.composition,
        additivesNutritional: blend.additives_nutritional,
        additivesTechnological: blend.additives_technological
      },
      pet: {
        petId: +pet.pet_id,
        name: pet.name,
        dateOfBirth: pet.date_of_birth,
        age: pet.age,
        weight: pet.weight,
        activityName: pet.activity_name,
        activityLevelDescription: pet.activity_level_description,
        bodyConditionId: +pet.body_condition_id,
        bodyConditionName: pet.body_condition_name,
        bodyConditionDescription: pet.body_condition_description
      },
      feedingPlanDetails: {
        targetDailyKcal: feedingPlanDetails.target_daily_kcal,
        otherKcalPercent: feedingPlanDetails.other_kcal_percent,
        scoopPosition: feedingPlanDetails.scoop_position,
        scoopsPerFeed: feedingPlanDetails.scoops_per_feed,
        feedPerDay: feedingPlanDetails.feeds_per_day,
        weightPerDayGrams: feedingPlanDetails.grams_per_day,
        weightPerOrderGrams: feedingPlanDetails.order_weight,
        orderDays: feedingPlanDetails.order_days
      },
      success: true
    };
  }

  public getFeedingPlanData(petId: number, feedingPlanId: number): Promise<FeedingPlanData> {
    return axios
      .get(`${this.baseUrl}${petId}/feeding-plan-history/${feedingPlanId}`)
      .then(response => {
        const json_result = response.data.resp.feeding_plan;
        return FeedingPlanService.buildFeedingPlanDataFromJson(json_result);
      })
      .catch(() => {
        return { success: false };
      });
  }
}

const feedingPlanService = new FeedingPlanService();
export default feedingPlanService;
