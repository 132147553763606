
import round from "@/utils/round.function";
import Vue from "vue";

enum presentationEnum {
  PERCENTAGE,
  KCAL
}

export default Vue.extend<any, any, any, any>({
  name: "PortionBar",
  props: {
    targetKcalPercentage: {
      type: Number,
      required: true
    },
    dailyTargetKcal: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      presentationEnum: presentationEnum,
      showIn: presentationEnum.KCAL
    };
  },
  computed: {
    targetKcal() {
      return (this.dailyTargetKcal * this.targetKcalPercentage) / 100;
    },
    otherKcal() {
      return this.dailyTargetKcal - this.targetKcal;
    },
    planFoodDisplay() {
      return this.showIn === presentationEnum.KCAL
        ? `${round(this.targetKcal, 0)} kcal`
        : `${this.targetKcalPercentage}%`;
    },
    otherFoodDisplay() {
      return this.showIn === presentationEnum.KCAL
        ? `${round(this.otherKcal, 0)} kcal`
        : `${100 - this.targetKcalPercentage}%`;
    }
  },
  filters: {
    round: round
  }
});
