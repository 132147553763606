
import Vue from "vue";
import { EnvironmentVariables } from "@/services/environmentVariables.service";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentAPI, CurrentPaymentMethodDTO } from "@/api/v2/payment";

export class StripeCardholderValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "StripeCardholderValidationError";
  }
}
export default Vue.extend<any, any, any, any>({
  name: "StripePayment",
  data() {
    return {
      customerId: +this.$route.params.customer_id,
      petId: +this.$route.params.pet_id,
      stripePublicKey: EnvironmentVariables.stripePublicKey(),
      stripeClient: null,
      clientSecret: "",
      elements: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      cardholderName: null,
      paymentMethod: null as CurrentPaymentMethodDTO | null
    };
  },
  methods: {
    async getSecretKey() {
      const response = await PaymentAPI.getSecretKey();
      if (!response.errorMessage) {
        this.clientSecret = response.clientSecret;
      }
    },

    async getPaymentMethod() {
      try {
        this.paymentMethod = await PaymentAPI.getCurrentPaymentMethod(this.customerId);
      } catch (e) {
        this.paymentMethod = { paymentMethodExists: false };
      }
    },

    async initStripe(): Promise<void> {
      this.stripeClient = await loadStripe(this.stripePublicKey);
      const elements = await this.stripeClient.elements();
      this.elements.cardNumber = elements.create("cardNumber", {});
      this.elements.cardNumber.mount("#card-number-element");
      this.elements.cardExpiry = elements.create("cardExpiry", {});
      this.elements.cardExpiry.mount("#card-expiry-element");
      this.elements.cardCvc = elements.create("cardCvc", {});
      this.elements.cardCvc.mount("#card-cvc-element");
    },

    submit() {
      this.validateCardholderName();
      this.$refs.stripeForm.requestSubmit();
    },

    validateCardholderName() {
      if (!this.cardholderName) {
        throw new StripeCardholderValidationError("Your cardholder name is incomplete.");
      }
    },

    onSubmit(event: Event): void {
      event.preventDefault();
      this.submitCardSetup();
    },

    async submitCardSetup() {
      const billingDetails = {
        name: this.cardholderName
      };
      const response = await this.confirmCardSetupWithBillingDetails(
        this.clientSecret,
        this.elements.cardNumber,
        billingDetails
      );
      // process this response out
      this.$emit("onConfirmCardSetup", response);
    },

    async confirmCardSetupWithBillingDetails(
      secretKey: string,
      stripeCard: any,
      billingDetails: any
    ) {
      return this.stripeClient.confirmCardSetup(secretKey, {
        payment_method: {
          card: stripeCard,
          billing_details: billingDetails
        }
      });
    }
  },

  async mounted() {
    await Promise.all([this.getPaymentMethod(), this.getSecretKey()]);
    await this.initStripe();
  }
});
