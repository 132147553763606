
import CheckoutForm from "@/components/v2/Checkout/CheckoutForm.vue";
import WithAppBar from "@/layouts/WithAppBar.vue";
import store from "@/store";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default {
  name: "Checkout",
  components: { BreadCrumbs, WithAppBar, CheckoutForm },
  beforeRouteEnter(to: any, from: any, next: any) {
    if (store.getters.isAuthenticated && store.getters.vetVuetifyViews) {
      next();
      return;
    }
    next(`/customer/${to.params.customer_id}/customer-details`);
  }
};
