
import Vue from "vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";

type Data = {};

type Inject = {};

export default Vue.extend<Data, any, any, any & Inject>({
  name: "thank-you",
  components: { WithOldVetBar },
  inject: [],
  methods: {
    async toDashboard() {
      await this.$router.push({ name: "dashboard" });
    }
  }
});
