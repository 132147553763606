
import Vue, { PropType } from "vue";
import round from "@/utils/round.function";
import { OrderInfoDto } from "@/services/priceBreakdown.service";

export default Vue.extend({
  name: "CheckoutFeedingPlan",
  props: {
    orderInfo: { type: Object as PropType<OrderInfoDto> }
  },
  data() {
    return {
      showFeedingPlans: true
    };
  },
  mounted() {
    if (this.orderInfo.pets.length > 2) {
      this.showFeedingPlans = false;
    }
  },
  filters: {
    round
  }
});
