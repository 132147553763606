
import Vue, { PropType } from "vue";
import productService, {
  ProductActionResponse,
  ProductDTO,
  SubscriptionProductDTO
} from "@/services/product.service";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend<any, any, any, any>({
  name: "SubscriptionProductsSelector",
  components: { LoadingSpinner },
  props: {
    subscriptionId: {
      type: Number,
      required: true
    },
    allProducts: {
      type: Array as PropType<ProductDTO[]>,
      default: []
    },
    selectedProducts: {
      type: Array as PropType<SubscriptionProductDTO[]>,
      default: []
    },
    error: String,
    isLoading: Boolean
  },
  data() {
    return {
      actionError: null,
      isActionLoading: false
    };
  },
  computed: {
    selectedProductsIdentifiers() {
      return this.selectedProducts?.map(
        (product: SubscriptionProductDTO) => product.productIdentifier
      );
    },
    getProductsDisplay() {
      const selectedProductsIdentifiers = this.selectedProducts?.map(
        (product: SubscriptionProductDTO) => product.productIdentifier
      );
      return this.allProducts?.map((product: ProductDTO) => {
        return {
          ...product,
          active: selectedProductsIdentifiers.includes(product.productIdentifier)
        };
      });
    }
  },
  methods: {
    async handleSubscriptionProductCheckbox(product: ProductDTO & { active: boolean }) {
      this.actionError = null;
      this.isActionLoading = true;
      const actionResult = product.active
        ? await this.addProductToSubscription(product.productIdentifier)
        : await this.removeProductFromSubscription(product.productIdentifier);
      if (!actionResult.success) {
        this.actionError =
          "Could not update the product, please try again. If the error persists, please contact support.";
        // reset checkbox to previous value
        Object.assign(product, { active: !product.active });
      }
      this.isActionLoading = false;
      this.$emit("change", { active: product.active, success: actionResult.success });
    },
    addProductToSubscription(identifier: string): Promise<ProductActionResponse> {
      return productService.addToSubscription(this.subscriptionId, identifier);
    },
    removeProductFromSubscription(identifier: string): Promise<ProductActionResponse> {
      return productService.removeFromSubscription(this.subscriptionId, identifier);
    }
  }
});
