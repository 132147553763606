
import Vue from "vue";
import { SaveDietRequirementsResponse } from "@/api/api.dto";
import {
  ErrorMessages,
  HealthIssueSelection,
  mapToSaveDietRequestPayload
} from "@/components/SelectDietRequirements/SelectDietRequirements.vm";

import TherapeuticDiet from "@/components/SelectDietRequirements/TherapeuticDiet/TherapeuticDiet.vue";
import MaintenanceDiet from "@/components/SelectDietRequirements/MaintenanceDiet/MaintenanceDiet.vue";
import { HealthIssues } from "@/components/common.vm";
import SubscriptionSingleProductCheckbox, {
  ProductCheckbox
} from "@/components/SubscriptionProducts/SubscriptionSingleProductCheckbox.vue";
import productService, { ProductDTO, SubscriptionProductDTO } from "@/services/product.service";
import ChoiceSelector from "@/components/ChoiceSelector.vue";
import FortifloraProductSelector from "@/components/SubscriptionProducts/FortifloraProductSelector.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { RouteNames } from "@/router/routeNames";

export default Vue.extend<any, any, any, any>({
  name: "SelectDietRequirements",
  inject: ["Api"],
  props: ["petDetails", "customerId", "forceReselection"],
  components: {
    LoadingSpinner,
    FortifloraProductSelector,
    SubscriptionSingleProductCheckbox,
    TherapeuticDiet,
    MaintenanceDiet,
    ChoiceSelector
  },
  data() {
    return {
      dietType: "",
      errorMessages: {
        invalidSelection: "",
        saveDietRequirementsRequest: ""
      } as ErrorMessages,
      isValidSelection: false,
      therapeuticSelected: {} as HealthIssueSelection,
      maintenanceSelected: {} as HealthIssueSelection,
      productToUpdate: null as ProductCheckbox | null,
      product: null as ProductDTO | null,
      subscriptionProducts: [] as Array<SubscriptionProductDTO>,
      areProductsLoading: true,
      productResponseError: null,
      showProduct: false
    };
  },
  async mounted() {
    this.dietType = this.forceReselection ? "" : this.petDetails.dietType;
    this.mapSavedHealthIssuesToSelected(this.petDetails.healthIssue);
    this.showProduct =
      Object.keys(this.therapeuticSelected).length > 0 ||
      Object.keys(this.maintenanceSelected).length > 0;
    await this.loadProducts();
  },
  watch: {
    dietType() {
      this.clearErrors();
    }
  },
  methods: {
    clearErrors() {
      this.errorMessages = {
        invalidSelection: "",
        saveDietRequirementsRequest: ""
      };
    },
    async onCancelButtonClicked() {
      await this.$router.push({
        name: "nutritional-plan",
        params: { pet_id: this.$props.petDetails.petId }
      });
    },
    async onContinueButtonClicked() {
      if (this.isValidSelection) {
        await this.saveDietRequirements();
      } else {
        this.errorMessages = { invalidSelection: "Please select the health benefits for this pet" };
      }
    },
    async saveDietRequirements(): Promise<void> {
      const mappedIssues = mapToSaveDietRequestPayload(
        this.petDetails,
        this.dietType,
        this.dietType === "vet_therapeutic" ? this.therapeuticSelected : this.maintenanceSelected
      );

      const saveDietRequirementsResponse: SaveDietRequirementsResponse = await this.Api.saveDietRequirements(
        mappedIssues
      );
      // update product only for the old component
      const productsUpdated = this.fortifloraRawDisplay
        ? await this.updateProductsForSubscription()
        : true;
      if (saveDietRequirementsResponse.success && productsUpdated) {
        await this.$router.push({
          name: RouteNames.NUTRITIONAL_PLAN,
          params: { pet_id: this.$props.petDetails.petId, customer_id: this.customerId }
        });
      } else {
        this.errorMessages = {
          saveDietRequirementsRequest: saveDietRequirementsResponse.errorMessage
        };
      }
    },
    mapSavedHealthIssuesToSelected(healthIssues: HealthIssues): void {
      if (this.forceReselection) {
        return;
      }

      if (healthIssues.length > 0) {
        if (this.dietType === "vet_therapeutic") {
          this.therapeuticSelected.primary = healthIssues[0].healthIssueId;
          if (healthIssues.length > 1) {
            this.therapeuticSelected.secondary = healthIssues[1].healthIssueId;
          }
          if (healthIssues.length > 2) {
            this.therapeuticSelected.tertiary = healthIssues[2].healthIssueId;
          }
        } else if (this.dietType === "vet_maintenance") {
          this.maintenanceSelected.primary = healthIssues[0].healthIssueId;
          if (healthIssues.length > 1) {
            this.maintenanceSelected.secondary = healthIssues[1].healthIssueId;
          }
          if (healthIssues.length > 2) {
            this.maintenanceSelected.tertiary = healthIssues[2].healthIssueId;
          }
        }
      }
    },
    onHealthIssuesSelected(selected: HealthIssueSelection): void {
      if (this.dietType === "vet_therapeutic") this.therapeuticSelected = selected;
      else this.maintenanceSelected = selected;
      this.errorMessages = {
        invalidSelection: "",
        saveDietRequirementsRequest: ""
      };
    },
    clearInvalidSelectionErrorMessage(): void {
      this.errorMessages.invalidSelection = "";
    },
    clearHealthIssuesSelection(): void {
      this.selected = {};
      this.errorMessages = {
        invalidSelection: "",
        saveDietRequirementsRequest: ""
      };
      this.disabledProductCheckbox();
    },
    validateSelection(validated: boolean): void {
      this.isValidSelection = validated;
    },
    enableProductCheckbox(): void {
      this.showProduct = true;
    },
    disabledProductCheckbox(): void {
      this.showProduct = false;
    },
    onProductChange(productInfo: ProductCheckbox) {
      this.productToUpdate = productInfo;
    },
    async updateProductsForSubscription(): Promise<boolean> {
      this.productResponseError = null;
      let success = true;
      const subscriptionId = this.petDetails.subscriptionId;
      if (this.productToUpdate) {
        if (this.productToUpdate.checked) {
          const response = await productService.addToSubscription(
            subscriptionId,
            this.productToUpdate.identifier
          );
          success = response.success;
        } else {
          const response = await productService.removeFromSubscription(
            subscriptionId,
            this.productToUpdate.identifier
          );
          success = response.success;
        }
      }
      if (!success) {
        this.productResponseError =
          "Could not update the product, please try again. If the error persists, please contact support.";
      }
      return success;
    },
    async loadProducts() {
      try {
        const [products, subscriptionProducts] = await Promise.all([
          productService.getProducts(),
          productService.getSubscriptionProducts(this.petDetails.subscriptionId)
        ]);
        this.product = products[0];
        this.subscriptionProducts = subscriptionProducts;
      } catch {
        this.productResponseError =
          "Could not load products. If the error persists, please contact support.";
      } finally {
        this.areProductsLoading = false;
      }
    }
  },
  computed: {
    fortifloraRawDisplay() {
      return !this.product?.descriptionSections;
    }
  }
});
