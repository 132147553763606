
import WithAppBar from "@/layouts/WithAppBar.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
import ConfirmModal from "@/components/v2/ConfirmModal.vue";
import subscriptionService from "@/services/subscription.service";
import PortionBar from "@/components/v2/PortionBar.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default Vue.extend<any, any, any, any>({
  name: "EditPortion",
  components: { BreadCrumbs, PortionBar, ConfirmModal, WithAppBar },
  data() {
    return {
      targetDailyKcal: 0,
      otherFoodPercentage: 0,
      showConfirmationModal: false,
      calorieGuideTableItems: [
        { name: "30g Piece of cheese", kcalValue: 170 },
        { name: "Medium dental chew", kcalValue: 69 },
        { name: "50g Sausage", kcalValue: 150 },
        { name: "1 Digestive biscuit", kcalValue: 70 },
        { name: "½ Apple", kcalValue: 20 },
        { name: "½ Slice white buttered toast", kcalValue: 80 },
        { name: "25g Ham", kcalValue: 40 },
        { name: "28g Lean roast beef", kcalValue: 50 },
        { name: "20g Chicken", kcalValue: 30 }
      ],
      rules: {
        targetKcalRules: [
          (value: string) =>
            (this.feedingPlan.kcalRange.min <= Number(value) &&
              this.feedingPlan.kcalRange.max >= Number(value)) ||
            `Kcal value need to be inbetween ${this.feedingPlan.kcalRange.min} and ${this.feedingPlan.kcalRange.max}`
        ],
        otherFoodPercentageRules: [
          (value: string) =>
            (Number(value) <= 50 && Number(value) >= 0) ||
            "Percentage of other food cannot be higher than 50%",
          (value: string) => !!value || "This field is required"
        ]
      }
    };
  },
  methods: {
    calculateDailyEnergyRequirement(treatKcal: number, totalKcal: number) {
      return Math.round((treatKcal / totalKcal) * 100);
    },
    resetTargetKcal() {
      this.targetDailyKcal = this.originalDailyKcalRequirement;
    },
    save() {
      const petId = this.petDetails.petId;
      subscriptionService
        .changeKcalTarget({
          pet_id: petId,
          target_daily_kcal: this.targetDailyKcal,
          target_kcal_multiplier: this.kcalMultiplier,
          other_kcal_percent: this.otherFoodPercentage
        })
        .then(() => {
          this.backToNutritionalPlan();
        });
    },
    submitChange() {
      if (this.petDetails.subscriptionStatus === "active") {
        this.showConfirmationModal = true;
      } else {
        this.save();
      }
    },
    backToNutritionalPlan() {
      this.$router.push({
        name: this.routeNames.NUTRITIONAL_PLAN,
        params: { pet_id: this.petDetails.petId, customer_id: +this.$route.params.customer_id }
      });
    }
  },
  computed: {
    ...mapGetters(["originalDailyKcalRequirement", "totalDailyKcalRequirement"]),
    feedingPlan() {
      return this.$store.state.feedingPlan;
    },
    petDetails() {
      return this.$store.state.petDetails;
    },
    dailyDryKcalPercentage() {
      return 100 - this.otherFoodPercentage;
    },
    kcalMultiplier() {
      return this.targetDailyKcal / this.originalDailyKcalRequirement;
    }
  },
  mounted() {
    this.otherFoodPercentage = 100 - this.feedingPlan.targetKcalPercent;
    this.targetDailyKcal = this.totalDailyKcalRequirement;
  }
});
