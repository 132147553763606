
import Vue from "vue";
import PaymentForm from "@/components/v2/Payment/PaymentForm.vue";
import PaymentInformation from "@/components/v2/Payment/PaymentInformation.vue";
import LoadingScreen from "@/components/v2/LoadingCircle.vue";
import WithAppBar from "@/layouts/WithAppBar.vue";
import { RouteNames } from "@/router/routeNames";
import { OrderInfoDto } from "@/services/priceBreakdown.service";
import BreadCrumbs from "@/components/BreadCrumbs.vue";

export default Vue.extend<any, any, any, any>({
  name: "Payment",
  components: {
    BreadCrumbs,
    WithAppBar,
    LoadingScreen,
    PaymentInformation,
    PaymentForm
  },
  data() {
    return {
      orderInfo: null as OrderInfoDto | null,
      loading: false,
      errorMessage: "",
      errorTimeout: null
    };
  },
  methods: {
    refreshPrice(newOrderInfo: OrderInfoDto) {
      this.orderInfo = newOrderInfo;
    },
    onSuccess() {
      this.loading = false;
      this.$router.push({ name: RouteNames.PAYMENT_THANK_YOU });
    },
    onError(error: { message: string; source: string }) {
      this.loading = false;
      this.errorMessage = error.message;
      this.removeErrorAfter(5000);
    },
    submitPayment() {
      try {
        this.loading = true;
        this.errorMessage = "";
        this.$refs.paymentForm.submit();
      } catch (error) {
        this.errorMessage = error.message;
        this.loading = false;
        this.removeErrorAfter(5000);
      }
    },
    removeErrorAfter(ms: number) {
      if (this.errorTimeout) {
        clearTimeout(this.errorTimeout);
      }
      this.errorTimeout = setTimeout(() => {
        this.errorMessage = "";
      }, ms);
    }
  },
  computed: {
    submitButtonText() {
      return this.orderInfo ? `pay ${this.orderInfo.totalPrice} now` : "loading price";
    },
    buttonDisabled() {
      return !this.orderInfo || this.loading;
    }
  }
});
