
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";
import PaymentWrapper from "@/components/Payment/PaymentWrapper.vue";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";

type Data = {
  customerId: null | number;
  petId: null | number;
  customerHasPaymentMethod: boolean;
  last4digits?: string;
};

type Inject = {
  Api: ApiInterface;
};

export default Vue.extend<Data, any, any, any & Inject>({
  name: "PaymentDetails",
  inject: ["Api"],
  components: {
    WithOldVetBar,
    PaymentWrapper
  },
  data() {
    return {
      customerId: null,
      petId: null,
      customerHasPaymentMethod: false,
      last4digits: ""
    };
  },
  methods: {},
  async mounted() {
    this.customerId = this.$route.params.customer_id;
    this.petId = this.$route.params.pet_id;
  }
});
