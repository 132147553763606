import { SaveDietRequirementsRequest } from "@/api/api.dto";
import { PetDetails } from "@/components/common.vm";

export type ErrorMessages = {
  saveDietRequirementsRequest?: string;
  invalidSelection?: string;
};

export type HealthIssueSelection = {
  primary?: number;
  secondary?: number;
  tertiary?: number;
};

export function mapToSaveDietRequestPayload(
  petDetails: PetDetails,
  dietType: string,
  healthIssueSelection: HealthIssueSelection
): SaveDietRequirementsRequest {
  return {
    pet_id: petDetails.petId,
    diet_type: dietType.substring(4), // endpoint returns eg. `vet_maintenance` but accepts only `maintenance`
    primary_health_issue_id: healthIssueSelection.primary,
    secondary_health_issue_id: healthIssueSelection.secondary
      ? healthIssueSelection.secondary
      : undefined,
    tertiary_health_issue_id: healthIssueSelection.tertiary
  } as SaveDietRequirementsRequest;
}
