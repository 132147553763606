
import Vue from "vue";
import { BenefitsDTO } from "@/api/v2/feeding-plan";

export default Vue.extend({
  name: "BenefitRecord",
  props: {
    benefit: Object as () => BenefitsDTO,
    order: Number
  }
});
