
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ReactivateSubscription",
  inject: ["Api"],
  props: {
    petName: {
      type: String,
      required: true
    },
    petId: {
      type: Number,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reactivateErrorMessage: null,
      loading: false
    };
  },
  methods: {
    async onReactivate() {
      this.loading = true;
      const response = await this.Api.reactivateSubscription(this.petId);
      if (response.success) {
        await this.$router.push({ name: "thank-you" });
      } else {
        this.reactivateErrorMessage = response.errorMessage;
        this.loading = false;
      }
    }
  }
});
