
import Vue from "vue";

export default Vue.extend({
  name: "CheckoutBag",
  props: ["orderInfo"],
  data() {
    return {
      showNutritionalPlans: true
    };
  },
  mounted() {
    this.showNutritionalPlans = this.orderInfo.pets.length <= 2;
  }
});
