const HEALTH_ISSUE_IDS = {
  ALLERGY: 5,
  CONSTIPATION: 6,
  DERMATITIS: 7,
  HIGH_FIBER_DIET: 8,
  GASTRITIS: 9,
  ENTERITIS_COLITIS: 10,
  LYMPHANGIECTASIA: 11,
  MALABSORPTION_MALDIGESTION_EPI: 12,
  HYDROLYSED_DIET: 13,
  OBESITY: 14,
  OSTEOARTICULAR_SUPPORT: 15,
  PANCREATITIS: 16,
  DIGESTIVE_REACTION: 17,
  WOUND_HEALING: 18
};

const therapeutic_selectable_options = {
  [HEALTH_ISSUE_IDS.ALLERGY]: {
    health_issue_id: HEALTH_ISSUE_IDS.ALLERGY,
    display_name: "Allergy: elimination diet for food trials"
  },
  [HEALTH_ISSUE_IDS.CONSTIPATION]: {
    health_issue_id: HEALTH_ISSUE_IDS.CONSTIPATION,
    display_name: "Constipation",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.HIGH_FIBER_DIET,
      display_name: "Will a high fiber diet be required?"
    },
    description: "NOT SUITABLE FOR PUPPIES In case of high fiber diet"
  },
  [HEALTH_ISSUE_IDS.DERMATITIS]: {
    health_issue_id: HEALTH_ISSUE_IDS.DERMATITIS,
    display_name: "Dermatitis",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.ALLERGY,
      display_name: "Is this condition caused by an allergic response?"
    }
  },
  [HEALTH_ISSUE_IDS.DIGESTIVE_REACTION]: {
    health_issue_id: HEALTH_ISSUE_IDS.DIGESTIVE_REACTION,
    display_name: "Digestive adverse reaction to food",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.ALLERGY,
      display_name: "Is this condition caused by an allergic response?"
    }
  },
  [HEALTH_ISSUE_IDS.ENTERITIS_COLITIS]: {
    health_issue_id: HEALTH_ISSUE_IDS.ENTERITIS_COLITIS,
    display_name: "Enteritis/colitis",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.HIGH_FIBER_DIET,
      display_name: "Will a high fiber diet be required?"
    },
    description: "NOT SUITABLE FOR PUPPIES In case of high fiber diet"
  },
  [HEALTH_ISSUE_IDS.GASTRITIS]: {
    health_issue_id: HEALTH_ISSUE_IDS.GASTRITIS,
    display_name: "Gastritis",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.ALLERGY,
      display_name: "Is this condition caused by an allergic response?"
    }
  },
  [HEALTH_ISSUE_IDS.LYMPHANGIECTASIA]: {
    health_issue_id: HEALTH_ISSUE_IDS.LYMPHANGIECTASIA,
    display_name: "Lymphangiectasia",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.HYDROLYSED_DIET,
      display_name: "Is an hydrolysed diet required?"
    }
  },
  [HEALTH_ISSUE_IDS.MALABSORPTION_MALDIGESTION_EPI]: {
    health_issue_id: HEALTH_ISSUE_IDS.MALABSORPTION_MALDIGESTION_EPI,
    display_name: "Malabsorption/maldigestion/EPI",
    follow_up: {
      health_issue_id: HEALTH_ISSUE_IDS.HYDROLYSED_DIET,
      display_name: "Is an hydrolysed diet required?"
    }
  },
  [HEALTH_ISSUE_IDS.OBESITY]: {
    health_issue_id: HEALTH_ISSUE_IDS.OBESITY,
    display_name: "Obesity",
    description: "NOT SUITABLE FOR PUPPIES"
  },
  [HEALTH_ISSUE_IDS.OSTEOARTICULAR_SUPPORT]: {
    health_issue_id: HEALTH_ISSUE_IDS.OSTEOARTICULAR_SUPPORT,
    display_name: "Osteoarticular support"
  },
  [HEALTH_ISSUE_IDS.PANCREATITIS]: {
    health_issue_id: HEALTH_ISSUE_IDS.PANCREATITIS,
    display_name: "Pancreatitis"
  },
  [HEALTH_ISSUE_IDS.WOUND_HEALING]: {
    health_issue_id: HEALTH_ISSUE_IDS.WOUND_HEALING,
    display_name: "Wound healing"
  }
};

type HealthIssueOption = {
  health_issue_id: number;
  display_name: string;
  description?: string;
};

type SelectedOptions = {
  primary?: number;
  secondary?: number;
  tertiary?: number;
};

class TherapeuticSelectableHealthIssuesService {
  firstLevelHealthIssues() {
    const first_level: any[] = [];
    Object.keys(therapeutic_selectable_options).map(key => {
      const item = therapeutic_selectable_options[parseInt(key, 10)];
      first_level.push({
        health_issue_id: item.health_issue_id,
        display_name: item.display_name,
        description: item.description ? item.description : ""
      });
      return 0;
    });
    return first_level;
  }

  getNextSelectableHealthIssues(selectedOptions: SelectedOptions): HealthIssueOption[] {
    if (this.isFirstSelection(selectedOptions)) {
      return this.firstLevelHealthIssues();
    }
    if (selectedOptions.primary && !selectedOptions.secondary) {
      const nextLevel = therapeutic_selectable_options[selectedOptions.primary];
      const followUp = nextLevel.follow_up;
      if (followUp) {
        return [{ health_issue_id: followUp.health_issue_id, display_name: followUp.display_name }];
      }
    }
    return [];
  }

  isFirstSelection(selectedOptions: SelectedOptions): boolean {
    return !selectedOptions.primary && !selectedOptions.secondary && !selectedOptions.tertiary;
  }

  getHealthIssueFollowUpQuestion(
    healthIssueId: number,
    healthIssueIds: number[]
  ): string | undefined {
    const healthIssue = therapeutic_selectable_options[healthIssueId];
    if (healthIssue?.follow_up && healthIssueIds.includes(healthIssue?.follow_up.health_issue_id)) {
      return healthIssue.follow_up.display_name;
    }
    return undefined;
  }
}

export {
  TherapeuticSelectableHealthIssuesService,
  HealthIssueOption,
  HEALTH_ISSUE_IDS,
  therapeutic_selectable_options
};
