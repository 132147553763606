const DOMAINS = ["vet2pet.org", "personalisedproplan.org"];

export class EnvironmentDetectionService {
  static isProduction(hostname: string): boolean {
    return (
      EnvironmentDetectionService.containsDomain(hostname) &&
      !EnvironmentDetectionService.isStaging(hostname) &&
      !EnvironmentDetectionService.isUAT(hostname)
    );
  }

  private static containsDomain(hostname: string): boolean {
    return DOMAINS.some(domain => hostname.includes(domain));
  }

  private static isStaging(hostname: string): boolean {
    return hostname.includes("staging");
  }

  private static isUAT(hostname: string): boolean {
    return hostname.includes("uat") || hostname.includes("test") || hostname.includes(".u.");
  }
}
