
import CheckoutBag from "@/components/v2/Checkout/CheckoutBag.vue";
import CheckoutHelp from "@/components/v2/Checkout/CheckoutHelp.vue";
import CheckoutPrice from "@/components/v2/Checkout/CheckoutPrice.vue";
import Vue from "vue";
import LoadingCircle from "@/components/v2/LoadingCircle.vue";
import DeliveryDates from "@/components/v2/DeliveryDates.vue";
import courierService, { DeliveryWindow } from "@/services/courier.service";
import PriceBreakdownService, { OrderInfoDto } from "@/services/priceBreakdown.service";

export default Vue.extend({
  name: "PaymentInformation",
  components: {
    DeliveryDates,
    LoadingCircle,
    CheckoutBag,
    CheckoutHelp,
    CheckoutPrice
  },
  data() {
    return {
      customerId: +this.$route.params.customer_id,
      deliveryWindow: null as DeliveryWindow | null,
      orderInfo: null as OrderInfoDto | null
    };
  },
  async mounted() {
    await Promise.all([this.loadOrderInfo(), this.loadDeliveryWindow()]);
  },
  methods: {
    async loadDeliveryWindow() {
      this.deliveryWindow = await courierService.getFirstAvailableWindow();
    },
    async loadOrderInfo() {
      this.orderInfo = await PriceBreakdownService.getOrderInfoForCustomer(
        this.customerId as number
      );
      this.$emit("onPriceChanged", this.orderInfo);
    }
  }
});
