
import Vue from "vue";
import { FeedingPlanData } from "@/services/feedingPlan.service";
import PortionSliderVisualization from "@/components/PortionAllocation/PortionSliderVisualization.vue";

export default Vue.extend<any, any, any, any>({
  name: "HistoricalNutritionalPlan",
  components: { PortionSliderVisualization },
  props: {
    feedingPlanData: {
      required: true,
      type: Object as () => FeedingPlanData
    },
    petId: Number,
    customerId: Number
  },
  methods: {
    pluralize(count: number, noun: string): string {
      return `${count} ${noun}${count !== 1 ? "s" : ""}`;
    },
    async toConditionHistory() {
      await this.$router.push({
        name: this.routeNames.CONDITION_HISTORY,
        params: { pet_id: `${this.petId}`, customer_id: this.customerId }
      });
    }
  }
});
