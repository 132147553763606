
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "PaginationControls",
  props: {
    value: Number,
    perPage: Number,
    totalRows: Number,
    align: {
      required: false,
      validator: val => ["right", "left", "center", "fill"].includes(val)
    }
  },
  data() {
    return {
      currentPage: this.value
    };
  },
  methods: {
    input(page: number) {
      this.$emit("input", page);
    }
  }
});
