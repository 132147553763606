
import Vue from "vue";

export default Vue.extend<any, any, any, any>({
  name: "OOCCModal",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    success: Boolean,
    first: String,
    last: String,
    email: String,
    error: String
  },
  methods: {
    onReturn() {
      this.showDialog = false;
      this.$emit("onReturn");
    },
    onRetry() {
      this.showDialog = false;
      this.$emit("onRetry");
    },
    onCancel() {
      this.showDialog = false;
      this.$emit("onCancel");
    }
  }
});
