
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";

import { mapPetDetailsResponseToViewModel, PetDetails } from "@/components/common.vm";
import SelectDietRequirements from "@/components/SelectDietRequirements/SelectDietRequirements.vue";
import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import store from "@/store";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import petService from "@/services/pet.service";

type Data = {
  errorMessage: string;
  petDetails: PetDetails;
  ready: boolean;
  isViewOrEditActiveMode: boolean;
  forceDietReselection: boolean;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  populatePetData(petId: number): Promise<void>;
};

export default Vue.extend<Data, Methods, {}, any & Inject>({
  name: "PetDietRequirements",
  components: {
    WithOldVetBar,
    SelectDietRequirements
  },
  inject: ["Api"],
  data() {
    return {
      errorMessage: "",
      petDetails: {} as PetDetails,
      ready: false,
      isViewOrEditActiveMode:
        store.state.consultationMode === ConsultationModes.VIEW_OR_EDIT_ACTIVE,
      forceDietReselection: false
    };
  },
  async beforeMount() {
    const petId = +this.$route.params.pet_id;
    this.forceDietReselection = await petService.shouldAlgorithmVersionChange(petId);
    await this.populatePetData(petId);
  },
  methods: {
    async populatePetData(petId: number): Promise<void> {
      const response = await this.Api.getPetById(petId);
      if (response.success) {
        this.petDetails = mapPetDetailsResponseToViewModel(response.petDetails);
        this.petDetails.petId = petId;
        this.ready = true;
      } else {
        this.ready = false;
        this.errorMessage = "Could not retrieve pet data. Please reload the page and try again.";
      }
    }
  }
});
