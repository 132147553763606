
import Vue from "vue";
import FeedingPlanDetails from "@/components/v2/FeedingPlan/FeedingPlanDetails/FeedingPlanDetails.vue";
import feedingPlanService, { FeedingPlanHistoryDate } from "@/services/feedingPlan.service";
import { BlendProductDTO, FeedingGuideDTO, CompositionDTO } from "@/api/v2/feeding-plan";
import { AgeDto } from "@/api/v2/pet";
import { nthDayDateDisplay } from "@/components/common.vm";

export default Vue.extend<any, any, any, any>({
  name: "FeedingPlanDetailsModal",
  components: { FeedingPlanDetails },

  data() {
    return {
      dialog: false,
      feedingPlan: null,
      selected: null
    };
  },
  props: {
    feedingPlanHistory: Array,
    petId: Number
  },
  computed: {
    pet() {
      return this.feedingPlan.pet;
    },
    blend() {
      return this.feedingPlan.blend;
    },
    feedingPlanDetails() {
      return this.feedingPlan.feedingPlanDetails;
    },
    showModalDisabled() {
      if (this.feedingPlanHistory && this.feedingPlanHistory.length > 0) {
        return false;
      }
      return true;
    },
    feedingPlanSelection() {
      return this.feedingPlanHistory.map((obj: FeedingPlanHistoryDate) => {
        if (obj.date) {
          return {
            text: nthDayDateDisplay(obj.date),
            value: obj.feedingPlanId
          };
        }
        return null;
      });
    },
    nutritionalInfo() {
      return {
        analyticalConstituents: this.blend.analyticalConstituents,
        additives: {
          nutritional: this.blend.additivesNutritional,
          technological: this.blend.additivesTechnological
        }
      };
    },
    composition() {
      return new CompositionDTO(this.blend.composition);
    },
    blendProduct() {
      return new BlendProductDTO(
        this.feedingPlanDetails.orderDays,
        this.feedingPlanDetails.weightPerDayGrams,
        this.feedingPlanDetails.weightPerOrderGrams
      );
    },
    feedingGuide() {
      return new FeedingGuideDTO(
        this.feedingPlanDetails.feedPerDay,
        this.feedingPlanDetails.scoopPosition,
        this.feedingPlanDetails.scoopsPerFeed
      );
    },
    dailyCalories() {
      return {
        ageDisplay: AgeDto.buildFromJson(this.pet.age).ageDisplay(),
        petId: this.pet.petId,
        petName: this.pet.name,
        petWeight: this.pet.weight,
        targetDailyDryKcal: this.feedingPlanDetails.targetDailyKcal,
        targetKcalPercent: 100 - this.feedingPlanDetails.otherKcalPercent,
        /* This values are missing from response component will handle incorrect data */
        breed: "",
        originalTargetKcal: 999999
      };
    }
  },
  async mounted() {
    if (this.feedingPlanHistory.length > 0) {
      this.selected = this.feedingPlanHistory[0].feedingPlanId;
    }
  },
  watch: {
    async selected() {
      await this.loadFeedingPlan();
    }
  },
  methods: {
    async loadFeedingPlan() {
      this.feedingPlan = await feedingPlanService.getFeedingPlanData(this.petId, this.selected);
    }
  }
});
